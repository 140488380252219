<template>
  <div>
    <v-img v-if="image" :src="image" contain />
    <h2 v-if="title" style="font-weight: 400;" class="ma-4 d-flex justify-start">
      {{ title }}
    </h2>
    <p v-if="description" v-text="description" class="ma-4" />
    <template v-if="task.hints && task.hints.length > 0">
      <v-divider></v-divider>
      <h3 style="font-weight: 400;" class="ma-4">Подсказки</h3>
      <div v-for="(hint, i) in task.hints" :key="i">
        <v-img v-if="image" :src="hint.image" contain />
        <p
          v-if="hint.description"
          v-text="hint.description"
          class="ma-4"
        />
      </div>
    </template>
    <template v-if="task.fact">
      <v-divider></v-divider>
      <h3 style="font-weight: 400;" class="ma-4">Интересный факт</h3>
      <div>
        <v-img v-if="task.fact.image" :src="task.fact.image" contain />
        <p
          v-if="task.fact.fact"
          v-text="task.fact.fact"
          class="ma-4"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import get from 'lodash/get';

export default {
  computed: {
    ...mapGetters({
      task: 'event/quest/currentTask',
    }),
    image() {
      return get(this.task, 'image') || null;
    },
    title() {
      return get(this.task, 'title') || null;
    },
    description() {
      return get(this.task, 'description') || null;
    }
  },
}
</script>