<template>
  <v-card
    :elevation="4"
    :style="{
      ...(leader
        ? {
            border: 'solid 5px #FFBF00',
            cornerRadius: '16px',
            backgroundColor: '#FFBF00',
            fontWeight: 'bold',
          }
        : {}),
    }"
  >
    <v-img :src="url" @dblclick="onDoubleClick" :aspect-ratio="aspectRatio" />
    <v-card-title
      class="gallery-title"
      v-if="author"
      :style="{
        ...(leader
          ? {
              backgroundColor: '#fff7d2',
            }
          : {}),
      }"
      >{{ author }}</v-card-title
    >
    <v-card-actions
      v-if="likeable || total"
      :style="{
        ...(leader
          ? {
              backgroundColor: '#fff7d2',
            }
          : {}),
      }"
    >
      <v-tooltip bottom color="primary" v-if="bonus">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="red" v-bind="attrs" class="ml-2" v-on="on"
            >mdi-gift-outline</v-icon
          >
          <span class="red--text ml-4 mr-4" v-bind="attrs" v-on="on">{{
            bonus
          }}</span>
        </template>
        <span>Бонусные баллы</span>
      </v-tooltip>
      <v-tooltip bottom color="primary" v-if="likeable">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="red"
            @click="onDoubleClick"
            text
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>{{
              isLikedByUser ? "mdi-heart" : "mdi-heart-outline"
            }}</v-icon>
          </v-btn>
        </template>
        <span>{{ isLikedByUser ? "Убрать лайк" : "Поставить лайк!" }}</span>
      </v-tooltip>
      <v-icon color="red" v-else>mdi-heart</v-icon>
      <span class="red--text ml-2" v-if="total">{{ total }}</span>
      <v-spacer></v-spacer>
      <v-tooltip bottom color="primary">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            @click="onZoomClick"
            text
            fab
            small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <span>Увеличить</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>
</template>

<script>
import likeableMixin from "@/mixins/likeable";

export default {
  name: "GalleryItem",
  props: [
    "url",
    "id",
    "author",
    "likeable",
    "total",
    "leader",
    "bonus",
    "aspect-ratio",
  ],
  mixins: [likeableMixin],
  computed: {
    likeableObjectId() {
      return this.id;
    },
  },
  methods: {
    onDoubleClick() {
      if (this.likeable) {
        this.isLikedByUser = !this.isLikedByUser;
        console.log(this.userLikes);
      }
    },
    onZoomClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
.gallery-bonus {
  font-weight: 400;
}
</style>
