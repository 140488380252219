// import _ from 'lodash';
import api from '@/api';
import Vue from 'vue';
import moment from 'moment-timezone';
// import DefaultStorage from '@/utils/storage';

export default {
  namespaced: true,
  state: {
    activeChallenge: null,
    activeChallengeTimer: null,
    activeChallengeRemaining: null,
    selectedChallenge: null,
    selectedChallengeTimer: null,
    selectedChallengeRemaining: null,
    challengeDialog: false,
    challengeTargetUser: null,
    closedCurrectChallengeId: null,
    supportedGames: [
      // {
      //   id: 'god-of-random',
      //   title: 'Бог Рандома'
      // },
      // {
      //   id: 'sea-battle',
      //   title: 'Морской Бой',
      // },
      {
        id: 'air-battle',
        title: 'Воздушный бой'
      }
    ],
    selectedGameId: 'air-battle',
  },
  getters: {
    activeChallenge: state => state.activeChallenge,
    activeChallengeRemaining: state => state.activeChallengeRemaining,
    selectedChallenge: state => state.selectedChallenge,
    selectedChallengeRemaining: state => state.selectedChallengeRemaining,
    challengeDialog: state => state.challengeDialog,
    challengeTargetUser: state => state.challengeTargetUser,
    supportedGames: state => state.supportedGames,
    selectedGameId: state => state.selectedGameId,
  },
  mutations: {
    openChallengeDialog: (state, user) => {
      state.challengeTargetUser = user;
      state.challengeDialog = true;
    },
    closeChallengeDialog: (state) => {
      state.challengeDialog = false;
      state.challengeTargetUser = null;
    },
    setSelectedGameId: (state, gameId) => {
      state.selectedGameId = gameId;
    },
    setActiveChallenge: (state, challenge) => {
      if (state.activeChallengeTimer != null) {
        clearInterval(state.activeChallengeTimer);
        state.activeChallengeTimer = null;
      }
      if (challenge != null && challenge.expireDate != null) {
        const expireDateM = moment(challenge.expireDate);
        if (expireDateM.isAfter(moment())) {
          state.activeChallengeTimer = setInterval(() => {
            const now = new Date();
            const nowM = moment(now);
            if (nowM.isBefore(expireDateM)) {
              const ms = expireDateM.toDate().getTime() - now.getTime();
              const duration = moment.duration(ms);
              const days = duration.days();
              const hours = days > 0 ? duration.hours() + days * 24 : duration.hours();
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              const remainingStr = `${hours}:${minutes >= 10 ? minutes : "0" + minutes}:${seconds >= 10 ? seconds : "0" + seconds
                }`;
              state.activeChallengeRemaining = remainingStr;
            } else {
              if (state.activeChallengeTimer != null) {
                clearInterval(state.activeChallengeTimer);
                state.activeChallengeTimer = null;
              }
              state.activeChallengeRemaining = null;
            }
          }, 1000);
        }
      }
      state.activeChallenge = challenge;
    },
    setSelectedChallenge: (state, challenge) => {
      if (state.selectedChallengeTimer != null) {
        clearInterval(state.selectedChallengeTimer);
        state.selectedChallengeTimer = null;
        // state.selectedChallengeRemaining = null;
      }
      if (challenge != null && challenge.expireDate != null) {
        const expireDateM = moment(challenge.expireDate);
        if (expireDateM.isAfter(moment())) {
          state.selectedChallengeTimer = setInterval(() => {
            const now = new Date();
            const nowM = moment(now);
            if (nowM.isBefore(expireDateM)) {
              const ms = expireDateM.toDate().getTime() - now.getTime();
              const duration = moment.duration(ms);
              const days = duration.days();
              const hours = days > 0 ? duration.hours() + days * 24 : duration.hours();
              const minutes = duration.minutes();
              const seconds = duration.seconds();
              const remainingStr = `${hours}:${minutes >= 10 ? minutes : "0" + minutes}:${seconds >= 10 ? seconds : "0" + seconds
                }`;
              state.selectedChallengeRemaining = remainingStr;
            } else {
              if (state.selectedChallengeTimer != null) {
                clearInterval(state.selectedChallengeTimer);
                state.selectedChallengeTimer = null;
              }
              state.selectedChallengeRemaining = null;
            }
          }, 1000);
        }
      }

      state.closedCurrectChallengeId = state.selectedChallenge != null ? state.selectedChallenge._id : null;
      state.selectedChallenge = challenge;
    },
  },
  actions: {
    async sendChallenge(context) {
      const { commit, state } = context;
      const { selectedGameId: gameId, challengeTargetUser: usr } = state;
      const { id: userId } = usr;
      commit('closeChallengeDialog');

      const payload = { to: userId, gameId };
      const data = await api.postChallenge(payload);
      Vue.$toast.open({
        type: data.ok ? 'info' : 'error',
        message: data.message,
        position: "top",
        duration: 5000,
        dismissible: true,
      });
    },
    async fetchChallenge(context, id) {
      const { commit, state } = context;
      const data = await api.fetchChallenge(id);
      commit('setActiveChallenge', data);
      if (data != null && state.selectedChallenge != null && data._id === state.selectedChallenge._id) {
        commit('setSelectedChallenge', data);
      }
    },
    async checkActiveChallenge(context) {
      const { commit, state } = context;
      const data = await api.checkActiveChallenge();
      commit('setActiveChallenge', data);
      if (data != null && state.selectedChallenge == null) {
        commit('setSelectedChallenge', data);
      }
    },
    async cancelChallenge(context, id) {
      await api.cancelChallenge(id);
    },
    async acceptChallenge(context, id) {
      await api.acceptChallenge(id);
    },
    async postChallengeChatMessage(context, { id, message }) {
      await api.postChallengeChatMessage({ id, message });
    },
    async activateChallenge(context, id) {
      await api.activateChallenge(id);
    },
    async activeChallenge(context, id) {
      await api.activeChallenge(id);
    },
    async runChallengeCommand(context, { id, command, params }) {
      await api.runChallengeCommand({ id, command, params });
    },
  },
};
