<template>
  <v-responsive
    :aspect-ratio="16 / 9"
    :class="{
      'pl-14': $vuetify.breakpoint.mobile,
      'pr-14': $vuetify.breakpoint.mobile,
    }"
  >
    <iframe
      v-if="params.type === 'youtube'"
      width="100%"
      height="100%"
      border="0"
      style="border: none"
      allowfullscreen
      :src="`https://www.youtube.com/embed/${params.url}${
        params.autoplay ? '?autoplay=1&mute=1' : ''
      }`"
    >
    </iframe>
    <video-player v-else :url="params.url" :autoplay="params.autoplay" />
  </v-responsive>
</template>

<script>
import VideoPlayer from "@/components/video/Index";

export default {
  props: ["params"],
  components: {
    VideoPlayer,
  },
};
</script>