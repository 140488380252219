<template>
  <div
    style="
      position: relative;
      width: 100%;
      height: 100%;
      background: transparent;
      background-position: center;
      background-size: cover;
      background-attachment: fixed;
    "
    class="pa-3"
    :style="{
      ...(theme.primary ? { backgroundColor: theme.primary } : null),
    }"
    ref="container"
  >
    <!-- Статус задания и кнопки -->
    <v-sheet
      class="mb-2 d-flex"
      ref="buttonsContainer"
      color="transparent"
      style="flex: 1"
      :style="{
        flexDirection: $vuetify.breakpoint.mobile ? 'column' : 'row',
      }"
    >
      <v-sheet class="pa-2" style="flex: 1" rounded elevation="3" color="#0008">
        <div class="justify-center d-flex">
          <v-fade-transition>
            <v-chip class="ma-2" :color="taskStatusColor" small dark>
              {{ taskStatusText }}
            </v-chip>
          </v-fade-transition>
        </div>
        <div class="d-flex justify-space-between text-body-2 white--text pa-2">
          <div>Баллов</div>
          <div>{{ gameTask.points }} / {{ questTask.points }}</div>
        </div>
        <div
          v-if="allHints.length > 0"
          class="d-flex justify-space-between text-body-2 white--text pa-2"
        >
          <div>Подсказок</div>
          <div>{{ gameTask.hintsUsed }} / {{ allHints.length }}</div>
        </div>
        <div
          v-if="questTask.attempts > 0"
          class="d-flex justify-space-between text-body-2 white--text pa-2"
        >
          <div>Попыток</div>
          <div>{{ gameTask.attempts }} / {{ questTask.attempts }}</div>
        </div>
        <div
          v-else
          class="d-flex justify-space-between text-body-2 white--text pa-2"
        >
          <div>Количество попыток не ограничено</div>
        </div>
        <div
          v-if="displayRemaining && remainingStr"
          class="white--text pa-2 justify-center d-flex"
        >
          {{ remainingStr }}
        </div>
      </v-sheet>
      <!-- Кнопки -->
      <v-sheet
        class="pa-4 d-flex"
        v-if="isButtonsContainerEnabled"
        rounded
        elevation="3"
        color="#0008"
        style="align-items: center; justify-content: center; z-index: 10"
        :class="{
          'mt-2': $vuetify.breakpoint.mobile,
          'ml-2': !$vuetify.breakpoint.mobile,
        }"
      >
        <v-fade-transition>
          <v-tooltip bottom v-if="isFactButtonEnabled">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                fab
                dark
                color="blue"
                small
                @click="onActivateFactClick"
                title="Интересный факт"
              >
                <v-icon dark> mdi-book-open-variant </v-icon>
              </v-btn>
            </template>
            <span>Интересный факт</span>
          </v-tooltip>
        </v-fade-transition>

        <v-fade-transition>
          <v-tooltip bottom v-if="isAnswerEnabled">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                fab
                dark
                color="red"
                small
                @click="onAnswerClick"
              >
                <v-icon dark> {{ answerButtonIcon }} </v-icon>
              </v-btn>
            </template>
            <span>{{ answerButtonToolTip }}</span>
          </v-tooltip>
        </v-fade-transition>

        <v-fade-transition>
          <v-tooltip bottom v-if="isHintsButtonEnabled">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2"
                fab
                dark
                color="blue"
                small
                @click="onActivateHintClick"
                title="Получить подсказку"
              >
                <v-icon dark> mdi-lightbulb-on </v-icon>
              </v-btn>
            </template>
            <span>Получить подсказку</span>
          </v-tooltip>
        </v-fade-transition>
      </v-sheet>
    </v-sheet>

    <!-- Контент задания -->
    <v-sheet
      class="pa-2"
      elevation="3"
      rounded
      color="#0008"
      style="overflow: scroll"
    >
      <v-sheet
        v-if="questTask.image"
        rounded
        class="mb-3 mr-2 ml-2 mt-2"
        color="#0000"
        style="overflow: hidden"
      >
        <v-img
          :src="`https://admin.yo.bahinsky.ru/api/public/image/${questTask.image}?width=640`"
        />
      </v-sheet>
      <div v-if="questTask.title" class="white--text text-h5 pb-2 pl-2">
        {{ questTask.title }}
      </div>
      <v-divider color="#0002"></v-divider>
      <slot name="answer-status"></slot>
      <!-- Статус задания -->
      <quest-task-status :gameTask="gameTask" :questTask="questTask" />
      <!-- Подсказки и интересный факт -->
      <v-fade-transition group>
        <v-sheet
          rounded
          class="blue lighten-5 black--text ma-2"
          v-for="(hint, i) in activatedHints"
          :key="`hint-content-${i}`"
        >
          <div class="text-caption pl-4 pt-4">Подсказка №{{ i + 1 }}</div>
          <div class="pa-2">
            <v-img
              v-if="hint.image"
              :src="`https://admin.yo.bahinsky.ru/api/public/image/${hint.image}?width=640`"
              contain
            />
            <quest-task-text-content
              v-if="hint.description"
              :value="linkifyHtml(hint.description.replace(/\n/gi, '<br/>'))"
              css-class="pa-2 text-body with-link"
            />
          </div>
        </v-sheet>
        <v-sheet
          rounded
          class="blue lighten-5 black--text ma-2"
          v-if="questTask.fact && gameTask.factActivated"
          :key="'fact-content'"
        >
          <div class="text-caption pl-4 pt-4">Интересный факт</div>
          <div class="pa-2">
            <v-img
              v-if="questTask.factImage"
              :src="`https://admin.yo.bahinsky.ru/api/public/image/${questTask.factImage}?width=640`"
              contain
            />
            <quest-task-text-content
              v-if="questTask.fact !== ''"
              :value="linkifyHtml(questTask.fact.replace(/\n/gi, '<br/>'))"
              css-class="pa-2 text-body"
            />
          </div>
        </v-sheet>
      </v-fade-transition>
      <!-- Описание задания -->
      <template v-if="questTask.description">
        <v-divider></v-divider>
        <quest-task-text-content
          v-if="questTask.description"
          :value="linkifyHtml(questTask.description.replace(/\n/gi, '<br/>'))"
          css-class="ma-2 white--text"
        />
      </template>
      <!-- Видео плеер -->
      <div class="mx-2" v-if="video">
        <video controls playsinline style="max-width: 100%" :key="video">
          <source :src="video" type="video/mp4" />
        </video>
      </div>
      <!-- Аудио плеер -->
      <div class="mx-2" v-if="audio">
        <vuetify-audio
          :file="audio"
          color="primary"
          :key="audio"
          flat
        ></vuetify-audio>
      </div>
    </v-sheet>

    <!-- Плавающие кнопки -->
    <v-sheet
      rounded
      elevation="3"
      class="mt-2 mb-2 mr-3 pa-4 d-flex"
      color="#0004"
      style="
        align-items: center;
        justify-content: center;
        z-index: 10;
        position: absolute;
        right: 0;
      "
      v-if="
        isButtonsContainerEnabled && scrollTop > buttonsContainerOffset + 16
      "
      :style="{ top: `${scrollTop}px` }"
    >
      <v-fade-transition>
        <v-tooltip bottom v-if="isFactButtonEnabled">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              dark
              color="blue"
              small
              @click="onActivateFactClick"
              title="Интересный факт"
            >
              <v-icon dark> mdi-book-open-variant </v-icon>
            </v-btn>
          </template>
          <span>Интересный факт</span>
        </v-tooltip>
      </v-fade-transition>

      <v-fade-transition>
        <v-tooltip bottom v-if="isAnswerEnabled">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              dark
              color="red"
              small
              @click="onAnswerClick"
            >
              <v-icon dark> {{ answerButtonIcon }} </v-icon>
            </v-btn>
          </template>
          <span>{{ answerButtonToolTip }}</span>
        </v-tooltip>
      </v-fade-transition>

      <v-fade-transition>
        <v-tooltip bottom v-if="isHintsButtonEnabled">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-2"
              fab
              dark
              color="blue"
              small
              @click="onActivateHintClick"
              title="Получить подсказку"
            >
              <v-icon dark> mdi-lightbulb-on </v-icon>
            </v-btn>
          </template>
          <span>Получить подсказку</span>
        </v-tooltip>
      </v-fade-transition>
    </v-sheet>

    <!-- input с файлов-ответом -->
    <input
      type="file"
      ref="file"
      :accept="uploadAcceptMimeType"
      @change="onFilePicked"
      style="display: none"
    />

    <!-- Слот для дополнительных функций в задании -->
    <slot name="utils"></slot>

    <!-- Диалог с ответом -->
    <quest-task-answer-dialog
      v-model="answerDialog"
      :gameTask="gameTask"
      :questTask="questTask"
      :multiline="isMultiLine"
      @answer="onSubmitAnswerClick"
    />

    <!-- Диалог с активацией подсказки -->
    <quest-task-hint-dialog
      v-model="hintDialog"
      :gameTask="gameTask"
      :questTask="questTask"
      @hint-confirm="onHintConfirm"
      :show-price="true"
    />
  </div>
</template>

<script>
import get from "lodash/get";
import map from "lodash/map";
import omit from "lodash/omit";
import pick from "lodash/pick";
import find from "lodash/find";
import slice from "lodash/slice";
import last from "lodash/last";
import first from "lodash/first";
import linkify from "linkifyjs/html";
import moment from "moment-timezone";

import QuestTaskStatus from "./QuestTask/Status";
import QuestTaskAnswerDialog from "./QuestTask/AnswerDialog";
import QuestTaskHintDialog from "./QuestTask/HintDialog";
import QuestTaskTextContent from "./QuestTask/TextContent.vue";

import ThemeMixin from "../../../../mixins/eventTheme";

export default {
  name: "QuestTask",
  mixins: [ThemeMixin],
  props: [
    "questTask",
    "gameTask",
    "date",
    "is-admin",
    "yesterday-points-ratio",
  ],
  components: {
    VuetifyAudio: () => import("vuetify-audio"),
    QuestTaskStatus,
    QuestTaskAnswerDialog,
    QuestTaskHintDialog,
    QuestTaskTextContent,
  },
  data() {
    return {
      okStatuses: [
        "ready",
        "current",
        "pending",
        "postponed",
        "partial-success",
      ],
      wrongStatuses: ["success", "expired", "failed"],
      answerDialog: false,
      hintDialog: false,
      ok: false,
      showAnswerMessage: true,
      now: new Date(),
      nowTimer: null,
      container: null,
      buttonsContainer: null,
      scrollTop: null,
    };
  },
  created() {
    if (this.date) {
      this.nowTimer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    }
  },
  beforeDestroy() {
    if (this.nowTimer) {
      clearInterval(this.nowTimer);
      this.nowTimer = null;
    }
  },
  watch: {
    parentElement(el, prevEl) {
      if (prevEl != null) {
        prevEl.removeEventListener("scroll", this.onScrollHandler);
      }
      if (el != null) {
        el.addEventListener("scroll", this.onScrollHandler);
      }
    },
  },
  computed: {
    audio() {
      return this.questTask && this.questTask.audio;
    },
    video() {
      return this.questTask && this.questTask.video;
    },
    isAnswerEnabled() {
      if (!this.isAdmin && this.isTooEarly === true) return false;
      const { status: gameTaskStatus, attempts: gameTaskAttempts } =
        this.gameTask;
      const { attempts: questTaskAttempts, kind: questTaskType } =
        this.questTask;
      return (
        questTaskType !== "noanswer" &&
        this.okStatuses.includes(gameTaskStatus) &&
        (gameTaskAttempts < questTaskAttempts || questTaskAttempts === 0)
      );
    },
    isFactButtonEnabled() {
      const factActivated = get(this, "gameTask.factActivated") || false;
      const fact = get(this, "questTask.fact") || "";
      return !factActivated && !!fact;
    },
    isHintsButtonEnabled() {
      return this.activatedHints.length < this.allHints.length;
    },
    isButtonsContainerEnabled() {
      return (
        this.isAnswerEnabled ||
        this.isFactButtonEnabled ||
        this.isHintsButtonEnabled
      );
    },
    isMultiLine() {
      return !!find(get(this.questTask, "tags"), (t) => t === "multiline");
    },
    allHints() {
      return map(get(this, "questTask.hints"), (hint) => {
        const locale = omit(
          find(get(hint, "localization"), (l) => l.language === "ru"),
          ["_id", "language"]
        );
        return {
          ...pick(hint, ["_id", "image", "points"]),
          ...locale,
        };
      });
    },
    activatedHints() {
      const hintsUsed = get(this, "gameTask.hintsUsed") || 0;
      return slice(this.allHints, 0, hintsUsed);
    },
    questTaskKind() {
      return get(this, "questTask.kind");
    },
    isUploadableTask() {
      return ["video", "photo", "audio"].includes(this.questTaskKind);
    },
    uploadAcceptMimeType() {
      const kindMimeMapping = {
        video: "video/*",
        audio: "audio/*",
        photo: "image/*",
      };
      return kindMimeMapping[this.questTaskKind];
    },
    answerButtonIcon() {
      switch (this.questTaskKind) {
        case "video":
          return "mdi-video";
        case "photo":
          return "mdi-camera";
        case "audio":
          return "mdi-microphone";
        default:
          return "mdi-check";
      }
    },
    answerButtonToolTip() {
      switch (this.questTaskKind) {
        case "video":
          return "Отправить видео файл";
        case "photo":
          return "Отправить фото / картинку";
        case "audio":
          return "Отправить аудио файл";
        default:
          return "Ответить";
      }
    },
    taskStatus() {
      return this.gameTask.status;
    },
    taskStatusColor() {
      if (get(this.gameTask, "pendingStatus") === "pending") {
        return "info";
      }
      switch (get(this.gameTask, "status")) {
        case "ready":
        case "current":
        case "postponed":
          return "primary";
        case "success":
          return "green";
        case "partial-success":
          return "success";
        case "pending":
          return "info";
        case "expired":
        case "failed":
          return "error";
        default:
          return null;
      }
    },
    taskStatusText() {
      if (get(this.gameTask, "pendingStatus") === "pending") {
        return "На проверке";
      }
      switch (get(this.gameTask, "status")) {
        case "ready":
        case "current":
        case "postponed":
          return "Активно";
        case "success":
          return "Верный ответ";
        case "partial-success":
          return "Частично верный ответ";
        case "pending":
          return "Ожидает проверки";
        case "expired":
          return "Время истекло";
        case "failed":
          return "Неверный ответ";
        default:
          return null;
      }
    },
    startDate() {
      if (this.date == null) return null;
      const m = moment(this.date);
      if (!m.isValid()) return null;
      return m.startOf("day");
    },
    deadline() {
      if (this.date == null) return null;
      let m = moment(this.date);
      if (!m.isValid()) return null;

      let currentDay = m.format("YYYY-MM-DD");
      const tags = get(this.questTask, "tags") || [];
      while (tags.includes(currentDay)) {
        m = moment(currentDay);
        const d = moment(currentDay).add(1, "day");
        currentDay = d.format("YYYY-MM-DD");
      }
      return m.endOf("day");
    },
    nowStr() {
      if (this.now == null) return null;
      return moment(this.now).format("HH:mm:ss");
    },
    isTooLate() {
      if (this.now == null || this.deadline == null) return null;
      return this.deadline.isBefore(this.now, "days");
    },
    isTooEarly() {
      if (this.now == null || this.startDate == null) return null;
      return this.startDate.isAfter(this.now, "days");
    },
    displayRemaining() {
      const { status: gameTaskStatus } = this.gameTask;
      return this.okStatuses.includes(gameTaskStatus);
    },
    remainingStr() {
      if (this.isTooLate == true) {
        if (this.questTask.points > 0) {
          if (this.yesterdayPointsRatio === 0.5) {
            return "Время начисления 100% баллов истекло. Но половину еще можно получить…";
          } else if (this.yesterdayPointsRatio === 1.0) {
            return null;
          } else {
            return `Время начисления 100% баллов истекло. Но ${this.yesterdayPointsRatio}x еще можно получить…`;
          }
        } else {
          return null;
        }
      }
      if (this.deadline == null) return null;
      if (!this.isAnswerEnabled) return null;
      const ms = moment(this.deadline).toDate().getTime() - this.now.getTime();
      const duration = moment.duration(ms);
      const days = duration.days();
      const hours = days > 0 ? duration.hours() + days * 24 : duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      return `${hours}:${minutes >= 10 ? minutes : "0" + minutes}:${
        seconds >= 10 ? seconds : "0" + seconds
      }`;
    },
    parentElement() {
      return (
        this.container &&
        this.container.parentElement &&
        this.container.parentElement.parentElement
      );
    },
    buttonsContainerOffset() {
      try {
        return (
          this.buttonsContainer.offsetTop + this.buttonsContainer.offsetHeight
        );
      } catch (err) {
        return 0;
      }
    },
  },
  mounted() {
    this.container = this.$refs.container;
    this.buttonsContainer = this.$refs.buttonsContainer?.$el;
  },
  methods: {
    onAnswerClick() {
      if (!this.isUploadableTask) {
        this.answerDialog = true;
      } else {
        this.$refs.file.click();
      }
    },
    onSubmitAnswerClick(answer) {
      this.$emit("answer", answer);
      this.answerDialog = false;
    },
    onActivateFactClick() {
      this.$emit("fact-activation");
    },
    onActivateHintClick() {
      this.hintDialog = true;
    },
    onHintConfirm() {
      this.hintDialog = false;
      this.$emit("hint-activation");
    },
    onFilePicked(event) {
      const file = get(event, "target.files[0]");
      if (file != null) {
        this.$emit("answer-file-selected", file);
      }
    },
    linkifyHtml(raw) {
      const r = /\[([^\]]+)\]/gi;
      if (r.test(raw || "")) {
        return raw.replace(r, (str) => {
          const strWithourBrackets = str.substring(1, str.length - 1);
          const items = strWithourBrackets.split("|");
          if (items.length < 2) {
            return str;
          }
          const type = first(items);
          const link = last(items);
          const params = slice(items, 1, items.length).reduce((acc, item) => {
            const [name, ...rest] = item.split("=");
            if (!name || !rest || !rest.length) {
              return acc;
            }
            acc[name] = rest.join("");
            return acc;
          }, {});
          switch (type) {
            case "audio":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <audio controls class='mt-2' style='width: 100%;'>
                <source src='${link}' />
              </audio>`;
            case "video":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <video controls class='mt-2' style='width: 100%;'>
                <source src='${link}' />
              </video>`;
            case "image":
              return `
              ${
                params.title ? `<div class='text-h6'>${params.title}</div>` : ""
              }
              <a href="${link}" target="_blank">
                <img src="${link}" style="width: 100%" />
              </a>
              `;
            case "file":
              return `<a href="${link}" target="_blank">${
                params.title ? params.title : link
              }</a>`;
            case "link":
              return `<a href="${link}" target="_blank">${
                params.title ? params.title : link
              }</a>`;
            default:
              return str;
          }
        });
      } else {
        return linkify(raw || "");
      }
    },
    onScrollHandler(e) {
      this.scrollTop = e.target.scrollTop;
    },
  },
};
</script>

<style>
a.linkified {
  color: #42a5f5 !important;
}
</style>
