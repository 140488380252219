var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-4"},[(!_vm.isFinished)?_c('div',{staticClass:"ma-4 pb-2"},[_c('p',[_vm._v("Правила вызова:")]),_c('p',[_vm._v(" 2️⃣ разница в выпавшем номинале уходит в копилку того, чьё число выше (6:1 = +5) ")]),_c('p',[_vm._v("4️⃣ по итогам трёх раундов мы начисляем итог в зачёт обоих игроков")]),_c('p',[_vm._v(" 🔴🔴 Во благо честной игры за бездействие во время вызова НИКТО не получает баллов! ")])]):_vm._e(),(_vm.isFinished)?_c('div',{staticClass:"d-flex flex-row pa-4 mb-4",class:{
      'yellow lighten-3': _vm.isWin,
      'deep-orange lighten-3': _vm.isLoose,
      'cyan lighten-5': _vm.isDraw,
      'gray lighten-5': _vm.isCancelled,
    }},[(_vm.isWin)?_c('div',[_vm._v(" Поздравляем с победой! Ваш выигрыш: "+_vm._s(_vm.challenge.price)+" ")]):_vm._e(),(_vm.isLoose)?_c('div',[_vm._v("Увы! Ваш проигрыш: "+_vm._s(_vm.challenge.price))]):_vm._e(),(_vm.isDraw)?_c('div',[_vm._v("Ничья")]):_vm._e(),(_vm.isCancelled)?_c('div',[_vm._v("Отменено")]):_vm._e()]):_vm._e(),_c('v-simple-table',[_c('tbody',[_c('tr',{class:{
          'yellow lighten-3': _vm.challenge.winner === _vm.challenge.from.id,
        }},[_c('td',[_c('v-avatar',{staticClass:"ma-2",attrs:{"color":"primary"}},[(_vm.challenge.from.thumbnail)?_c('v-img',{attrs:{"src":_vm.challenge.from.thumbnail,"alt":_vm.challenge.from.name}}):_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.getInitials(_vm.challenge.from.name)))])],1)],1),_c('td',[_vm._v(_vm._s(_vm.challenge.from.name))]),_vm._l((_vm.rolls),function(roll,index){return _c('td',{key:("round-from-" + index)},[_c('h2',[_vm._v(_vm._s(roll["from"]))])])}),_c('td')],2),_c('tr',{class:{
          'yellow lighten-3': _vm.challenge.winner === _vm.challenge.to.id,
        }},[_c('td',[_c('v-avatar',{staticClass:"ma-2",attrs:{"color":"primary"}},[(_vm.challenge.to.thumbnail)?_c('v-img',{attrs:{"src":_vm.challenge.to.thumbnail,"alt":_vm.challenge.to.name}}):_c('span',{staticClass:"white--text text-h6"},[_vm._v(_vm._s(_vm.getInitials(_vm.challenge.to.name)))])],1)],1),_c('td',[_vm._v(_vm._s(_vm.challenge.to.name))]),_vm._l((_vm.rolls),function(roll,index){return _c('td',{key:("round-to-" + index)},[_c('h2',[_vm._v(_vm._s(roll["to"]))])])}),_c('td')],2)])]),_c('div',{staticClass:"d-flex flex-row pa-4 justify-center"},[_c('v-btn',{attrs:{"disabled":!_vm.rollEnabled,"color":"primary"},on:{"click":_vm.onMakeRoll}},[_vm._v(_vm._s(!_vm.isFinished && _vm.remaining ? (_vm.remaining + " | ") : "")+"Бросить кубик")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }