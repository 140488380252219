<template>
  <v-sheet elevation="4" :height="620" :max-width="500">
    <v-toolbar color="primary" dark flat class="mb-2">
      <v-tabs :value="selectedRoomIndex" @change="setSelectedRoomIndex($event)">
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab v-for="room in rooms" :key="room.id">
          {{ room.title }}
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="secondary"
        @click="$emit('close')"
      >
        <v-icon dark> mdi-close </v-icon>
      </v-btn>
    </v-toolbar>
    <div class="d-flex" style="width: 100%;">
      <div style="width: 100%;">
        <div
          class="mb-2 pl-2 pr-2"
          style="height: 454px; overflow: auto; width: 100%"
          ref="messages"
        >
          <div
            v-for="item of roomMessages"
            :key="`chat-message-${item.id}`"
            :style="{
              cursor:
                userId === item.author.id || item.room === 'tech-support'
                  ? 'default'
                  : 'pointer',
            }"
            :title="
              userId === item.author.id || item.room === 'tech-support'
                ? ''
                : 'Двойное нажатия для игнора'
            "
            @dblclick="onMessageDblClick(item)"
          >
            <strong>{{
              userId === item.author.id ? "Я" : item.author.name
            }}</strong
            >: {{ item.data.text }}
          </div>
        </div>
        <div class="pl-2 pr-2 d-flex" style="width: 100%">
          <v-text-field
            label="Сообщение"
            placeholder="Введите ваше сообщение"
            outlined
            @keyup="onKeyPress"
            :value="message"
            @input="onMessageChange"
            hint="сообщение до 300 символов"
            persistent-hint
            min-width="300px"
          />
          <v-btn
            color="primary"
            dark
            class="mt-2 ml-2"
            :disabled="message == null || !message.trim()"
            @click="sendMessage"
            ><v-icon>mdi-send</v-icon></v-btn
          >
        </div>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import _ from "lodash";

export default {
  name: "ChatContent",
  props: ["params", "item-id"],
  data() {
    return {
      message: "",
    };
  },
  watch: {
    userId() {
      this.resetChat();
    },
    roomMessages() {
      this.$nextTick(() => {
        this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight + 100;
      });
    },
  },
  methods: {
    ...mapActions({
      runCommand: "event/runCommand",
      banUser: "event/chat/banUser",
    }),
    ...mapMutations({
      appendMyMessage: "event/chat/appendMyMessage",
      resetChat: "event/chat/resetChat",
      setSelectedRoomIndex: "event/chat/setSelectedRoomIndex",
      setLastChatReadTimeStamp: "event/setLastChatReadTimeStamp",
    }),
    onKeyPress(event) {
      const { code } = event;
      if (code === "Enter") {
        this.sendMessage();
      }
    },
    onMessageChange(event) {
      if (event && event.length < 300) {
        this.message = event;
      } else if (typeof event === "string" && event) {
        this.message = event.substring(0, 300);
      }
    },
    async sendMessage() {
      if (this.message) {
        const message = {
          id: new Date().getTime(),
          author: {
            id: this.userId,
            me: true,
          },
          data: {
            text: this.message,
          },
          type: "text",
          room: this.selectedRoomId,
        };
        if (this.selectedRoomId === "tech-support") {
          this.appendMyMessage(message);
        }
        this.message = null;

        const { _id: itemId } = this.chatItem;
        const { text } = message.data;
        const command = "chat|sendMessage";
        const params = {
          message: text,
          chatId: this.selectedRoomId,
        };
        try {
          await this.runCommand({
            itemId,
            command,
            params,
          });
        } catch (err) {
          if (err && err.message) {
            alert(err.message);
          }
        }
      }
    },
    onMessageDblClick(item) {
      if (item.author.id !== this.userId && item.author.id !== "tech-support") {
        this.banUser(item.author.id);
      }
    },
  },

  beforeDestroy() {
    this.setLastChatReadTimeStamp();
  },

  computed: {
    ...mapGetters({
      chatItem: "event/chatItem",
      user: "event/user",
      bans: "event/chat/bannedUsers",
    }),
    ...mapGetters({
      rooms: "event/chat/rooms",
      selectedRoomIndex: "event/chat/selectedRoomIndex",
      messages: "event/chat/messages",
    }),
    roomMessages() {
      return _.filter(
        this.messages,
        (msg) =>
          msg.room === this.selectedRoomId && !this.bans.includes(msg.author.id)
      );
    },
    userId() {
      return this.user ? this.user.id : null;
    },
    selectedRoomId() {
      return this.selectedRoom && this.selectedRoom.id;
    },
    selectedRoom() {
      return this.rooms[this.selectedRoomIndex];
    },
  },
};
</script>
