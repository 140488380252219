<template>
  <div class="d-flex align-center justify-center pa-10" style="font-size: 20pt; font-weight: 300; font-style: italic;">
    <template v-if="remaining != null">
      {{ this.days }} дней
      {{ this.hours }} ч
      {{ this.minutes }} мин
      {{ this.seconds }} сек
    </template>
  </div>
</template>

<script>
export default {
  props: ['params'],
  data() {
    return {
      timer: null,
      remaining: null,
      days: null,
      hours: null,
      minutes: null,
      seconds: null,
    };
  },
  watch: {
    remaining(val) {
      this.days = Math.floor(val / (1000 * 24 * 60 * 60));
      this.hours = Math.floor((val - 1000 * 24 * 60 * 60 * this.days) / 1000 / 60 / 60)
      this.minutes = Math.floor(val % (1000 * 60 * 60) / 1000 / 60);
      this.seconds = Math.floor(val % (1000 * 60) / 1000);
    }
  },
  created() {
    this.timer = window.setInterval(() => {
      this.$nextTick(() => {
        this.remaining = this.params.ts - new Date;
      });
    }, 1000);
  },
  beforeDestroy() {
    if (this.timer != null) {
      window.clearInterval(this.timer);
    }
  }
}
</script>