import { render, staticRenderFns } from "./TaskCard.vue?vue&type=template&id=a3dfae50&scoped=true&"
import script from "./TaskCard.vue?vue&type=script&lang=js&"
export * from "./TaskCard.vue?vue&type=script&lang=js&"
import style0 from "./TaskCard.vue?vue&type=style&index=0&id=a3dfae50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a3dfae50",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VImg,VSheet})
