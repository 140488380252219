<template>
  <div>
    <v-card class="mb-2">
      <v-card-title>Вы набрали баллов: {{ myPoints }}</v-card-title>
    </v-card>
    <v-simple-table v-if="items" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Место</th>
            <th class="text-left">Игрок</th>
            <th class="text-left">Баллы</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="item in items"
            :class="{
              warning: item.gameId === myGameId,
            }"
            :key="item.index"
          >
            <td>{{ item.index }}</td>
            <td class="user-name">{{ item.user }}</td>
            <td>{{ item.points }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "QuestStandings",
  props: ["items", "my-points", "my-game-id"],
};
</script>

<style scoped>
.user-name {
  text-transform: capitalize;
}
</style>