var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-markers'),_c('div',{staticClass:"b-battlefield b-canvas-wrapper"},[_c('div',{staticClass:"b-grid"},[_c('table',{staticClass:"b-grid-table"},[_c('tbody',[_c('tr',_vm._l((_vm.items),function(gridItem){return _c('td',{key:gridItem.index,staticClass:"b-grid-item",class:[
                gridItem.classes,
                {
                  'b-grid-item--miss': gridItem.miss,
                  'b-grid-item--miss-auto': gridItem.missAuto,
                  'b-grid-item--hit_': gridItem.hit,
                  'b-grid-item--hit': true,
                  'b-grid-item--empty': gridItem.empty,
                  'b-grid-item--done': gridItem.hit,
                } ],on:{"click":function($event){return _vm.onGridItemClick(gridItem)}}},[_c('div',{staticClass:"b-grid-item-content"},[_c('span',{staticClass:"b-grid-item-status"}),(gridItem.ship)?_c('span',{staticClass:"b-ship",class:[gridItem.ship.getClasses()]}):_vm._e()])])}),0)])])])]),_c('div',{staticClass:"mt-2"},[_vm._v("Поле противника")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }