<template>
  <v-sheet color="white" class="pa-4">
    <div
      v-for="(item, index) in items"
      :key="`faq-item-${index}-${item.q}`"
      class="my-4"
    >
      <div :key="`faq-question-${index}-${item.q}`" class="text-h5">
        {{ item.q }}
      </div>
      <div
        :key="`faq-answer-${index}-${item.q}`"
        v-html="item.a"
        class="my-3"
      />
    </div>
  </v-sheet>
</template>

<script>
import _ from "lodash";

export default {
  name: "FAQContent",
  props: ["params"],
  computed: {
    items() {
      return _.get(this.params, "items") || [];
    },
  },
};
</script>