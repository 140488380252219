<template>
  <div>
    <otr-2022-event v-if="eventId === 'otr2022'" />
    <otr-2023-event v-else-if="eventId === 'otr-2023'" />
    <otr-2024-event v-else-if="eventId === 'otr-2024'" />
    <sminex-2023-event v-else-if="eventId === 'sminex-2023'" />
    <app-event v-else :event-id="eventId" :right-menu="false" />
  </div>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";

export default {
  name: "AppPageEvent",
  components: {
    AppEvent,
    "otr-2022-event": () => import("./otr-2022/Index.vue"),
    "otr-2023-event": () => import("./otr-2023/Index.vue"),
    "otr-2024-event": () => import("./otr-2024/Index.vue"),
    "sminex-2023-event": () => import("./sminex-2023/Index.vue"),
  },
  data() {
    let domainEventId = null;
    const { hostname } = window.location;
    switch (hostname) {
      case "2022.otr.ru":
        domainEventId = "otr2022";
        break;
      case "advent.otr.ru":
        domainEventId = "otr-2024";
        break;
      case "effectsminex.online":
        domainEventId = "sminex-2023";
        break;
      default:
        domainEventId = null;
    }
    return {
      eventId: domainEventId || this.$route.params.id || "greenatom",
    };
  },
};
</script>