<template>
  <v-sheet
    rounded
    @click="
      if (!disabled) {
        $emit('click', gameTask._id);
      }
    "
    :style="{ cursor: disabled ? 'not-allowed' : 'pointer' }"
    style="cursor: pointer"
    class="quest-task-card"
  >
    <v-card class="mx-auto my-12">
      <v-img
        height="250"
        :src="
          `https://admin.yo.bahinsky.ru/api/public/image/${questTask.image}?width=480`
        "
      ></v-img>

      <v-card-title class="task-title">{{ questTask.title }}</v-card-title>

      <v-card-text>
        <v-chip class="ma-2" :color="taskStatusColor" small dark>
          {{ taskStatusText }}
        </v-chip>
      </v-card-text>
    </v-card>
  </v-sheet>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'QuestTaskCard',
  props: ['gameTask', 'questTask', 'disabled'],
  computed: {
    taskStatus() {
      return this.gameTask.status;
    },
    taskStatusColor() {
      if (get(this.gameTask, 'pendingStatus') === 'pending') {
        return 'info';
      }
      if (this.disabled) {
        return 'secondary';
      }
      switch (get(this.gameTask, 'status')) {
        case 'ready':
        case 'current':
        case 'postponed':
          return 'primary';
        case 'success':
          return 'green';
        case 'pending':
          return 'info';
        case 'expired':
        case 'failed':
          return 'error';
        default:
          return null;
      }
    },
    taskStatusText() {
      if (get(this.gameTask, 'pendingStatus') === 'pending') {
        return 'На проверке';
      }
      if (this.disabled) {
        return 'Заблокировано';
      }
      switch (get(this.gameTask, 'status')) {
        case 'ready':
        case 'current':
        case 'postponed':
          return 'Активно';
        case 'success':
          return 'Верный ответ';
        case 'pending':
          return 'Ожидает проверки';
        case 'expired':
          return 'Время истекло';
        case 'failed':
          return 'Неверный ответ';
        default:
          return null;
      }
    },
  },
};
</script>

<style scoped>
.task-title {
  word-break: normal;
}
.quest-task-card {
  transition: all 0.2s ease-in-out;
}
.quest-task-card:hover {
  transform: scale(1.1);
}
</style>
