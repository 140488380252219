import io from 'socket.io-client';
import moment from 'moment-timezone';
import AppNavBar from '@/components/NavBar';
import EventMenu from '@/components/event/Menu';
import EventContent from '@/components/event/Content';

import eventBus from '../utils/eventBus';

import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';

import get from 'lodash/get';
import pick from 'lodash/pick';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import filter from 'lodash/filter';
import orderBy from 'lodash/orderBy';
import first from 'lodash/first';

import themeMixin from './eventTheme';

const _ = { get, pick, isArray, map, filter, orderBy, first };

const defaultTheme = {
  primary: '#1976D2',
  secondary: '#424242',
  accent: '#82B1FF',
  error: '#FF5252',
  info: '#2196F3',
  success: '#4CAF50',
  warning: '#FFC107',
};

export default {
  props: ['eventId', 'mapping', 'auth'],

  mixins: [themeMixin],

  components: {
    AppNavBar,
    EventMenu,
    EventContent,
  },

  computed: {
    ...mapState(['drawer']),
    ...mapGetters({
      activeItem: 'event/activeItem',
      bgImage: 'event/bg',
      event: 'event/event',
      profile: 'event/profile',
      theme: 'event/theme',
      user: 'event/user',
      isAdmin: 'event/isAdmin',
      recentChatMesageTimeStamp: 'event/chat/recentMesageTimeStamp',
      lastChatReadTimeStamp: 'event/lastChatReadTimeStamp',
    }),
    chatEnabled() {
      return _.get(this, 'event.chat.enabled', false) || false;
    },
    title() {
      return this.event && this.event.title;
    },
    userId() {
      return this.user && this.user.id;
    },
    userToken() {
      return this.user && this.user.token;
    },
    authModes() {
      const modes = _.get(this.event, 'auth.modes');
      return isArray(modes) ? modes : [];
    },
    authModeSecret() {
      return this.authModes.includes('secret');
    },
    authModeJwt() {
      return this.authModes.includes('jwt');
    },
    authModeSignIn() {
      return this.authModes.includes('signin');
    },
    authModeSignUp() {
      return this.authModes.includes('signup');
    },
    authModeInvitation() {
      return this.authModes.includes('invitation');
    },
    timezone() {
      return _.get(this.event, 'timezome') || null;
    },
    alarms() {
      return _.filter(
        _.map(
          _.get(this.event, 'alarms'),
          ({ _id, alert, from, title, to }) => {
            const mFrom = this.timezone
              ? moment.tz(from, this.timezone)
              : moment(from);
            const mTo = this.timezone
              ? moment.tz(to, this.timezone)
              : moment(to);
            return {
              id: _id,
              alert,
              title,
              from: mFrom.isValid() ? mFrom.toDate() : new Date(),
              to: mTo.isValid() ? mTo.toDate() : null,
            };
          }
        ),
        ({ to }) => to != null
      );
    },
    pendingAlarms() {
      return _.orderBy(
        _.filter(this.alarms, ({ to }) => {
          return moment(to).isAfter(this.now || new Date());
        }),
        ['to'],
        ['asc']
      );
    },
    activeAlarms() {
      return _.filter(this.pendingAlarms, ({ from }) => {
        return moment(from).isBefore(this.now || new Date());
      });
    },
    activeAlarm() {
      return _.first(this.activeAlarms);
    },
    activeAlarmRemainingStr() {
      if (this.activeAlarm == null) return null;
      const ms = this.activeAlarm.to.getTime() - this.now.getTime();
      const duration = moment.duration(ms);
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();
      const value = `${hours}:${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds
        }`;
      return value;
    },
    nextAlarm() {
      return;
    },
    invitationFields() {
      return _.map(_.get(this.event, 'auth.invitation.fields'), (item) => item);
    },
    isInvitationFormValid() {
      if (this.invitationFields.length === 0) return false;
      for (const field of this.invitationFields) {
        const value = this.invitationFormData[field.id];
        if (value == null || value === '') {
          return false;
        }
      }
      return true;
    },
    isNewMessage() {
      if (this.recentChatMesageTimeStamp != null) {
        if (this.lastChatReadTimeStamp == null) return true;
        const r = moment(this.recentChatMesageTimeStamp);
        const l = moment(this.lastChatReadTimeStamp);
        return r.isValid() && l.isValid() && l.isBefore(r);
      }
      return false;
    },
  },

  watch: {
    theme(value) {
      const theme = {
        ...defaultTheme,
        ..._.pick(value, Object.keys(defaultTheme)),
      };
      Object.keys(theme).forEach((key) => {
        this.$vuetify.theme.themes.light[key] = theme[key];
        this.$vuetify.theme.themes.dark[key] = theme[key];
      });
    },
    userId(userId) {
      this.authUserSocket();
      if (userId != null) {
        this.initChallengeCheck();
      }
    },
    userToken() {
      this.authUserSocket();
    },
    activeAlarm(value, oldValue) {
      if (oldValue != null) {
        if (oldValue.id != (value && value.id)) {
          this.alertTitle = oldValue.title;
          this.alertMessage = oldValue.alert;
          this.alertDialog = true;
        }
      }
    },
    invitationFields() {
      this.initInvitationFormData();
    },
  },

  metaInfo() {
    const items = [
      this.activeItem && this.activeItem.title,
      this.event && this.event.title,
    ].filter((item) => item);
    return {
      title: items.join(' / '),
    };
  },

  beforeMount() {
    this.openIOSocket();
  },
  beforeDestroy() {
    this.closeIOSocket();
    if (this.nowTimer != null) {
      clearInterval(this.nowTimer);
      this.nowTimer = null;
    }
    if (this.checkChallengeTimer != null) {
      clearInterval(this.checkChallengeTimer);
      this.checkChallengeTimer = null;
    }
  },

  created() {
    this.initEvent(true);
  },

  methods: {
    ...mapMutations({
      setDrawer: 'drawer',
      appendTechSupportMessage: 'event/chat/appendTechSupportMessage',
    }),
    ...mapActions({
      getEvent: 'event/getEvent',
      getMyProfile: 'event/getMyProfile',
      authSignIn: 'event/authSignIn',
      authSignUp: 'event/authSignUp',
      authSignOut: 'event/authSignOut',
      appendChatRoomMessage: 'event/chat/appendChatRoomMessage',
      postEventInvitation: 'event/postEventInvitation',
      fetchChallenge: 'event/challenge/fetchChallenge',
      checkActiveChallenge: 'event/challenge/checkActiveChallenge',
    }),
    async initEvent(fetchEventInfo = true) {
      if (fetchEventInfo) {
        console.log('fetching event');
        await this.getEvent(this.eventId);
        console.log('event loaded');
      }
      // check if any secret provided in route query
      const secret = _.get(this.$route, 'query.secret');
      const token =
        _.get(this.$route, 'query.token') ||
        _.get(this.$route, 'query.email-token');
      const invitationCode = _.get(this.$route, 'query.invitation');
      if (this.authModeSecret && secret) {
        this.authSignIn({ secret });
        this.$router.replace({
          ...this.$route,
          query: {},
        });
      } else if (this.authModeJwt && token) {
        this.authSignIn({ token });
        this.$router.replace({
          ...this.$route,
          query: {},
        });
      } else if (this.authModeInvitation) {
        console.log('cheking invitation');
        let user = null;
        try {
          console.log('fetching profile');
          user = await this.getMyProfile();
          console.log('profile loaded', user);
        } catch (err) {
          user = null;
        }
        if (invitationCode) {
          console.log('cheking invitation');
          this.invitationCode = invitationCode;
          if (user == null) {
            const { data: invitationCheck } = await this.postEventInvitation(
              invitationCode
            );
            if (invitationCheck.ok) {
              this.invitationFormData = {};
              this.initInvitationFormData();
            }
            this.invitationDialog = invitationCheck.ok;
            this.invitationWrongDialog = !invitationCheck.ok;
            if (invitationCheck.ok) {
              console.log('intivation OK');
              this.initInvitationFormData();
            }
          }
        } else if (user == null) {
          console.log('wrong invitation');
          this.invitationWrongDialog = true;
        }
      } else {
        console.log('fetching profile');
        this.getMyProfile();
      }
      console.log('init timer');
      this.initTimer();
    },
    authUserSocket() {
      if (this.socket && this.userToken) {
        this.socket.emit('auth', this.userToken);
      }
    },
    openIOSocket() {
      const socketIoPath = '/api/ws';
      const socket = io('', {
        path: socketIoPath,
        query: {},
      });
      socket.on('connect', () => {
        this.socket = socket;
        if (this.userId) {
          this.authUserSocket();
        }
      });
      socket.on('event:update', (message) => {
        const {
          event: { id },
        } = message;
        if (this.event && this.event.id === id) {
          const randomTimeout = Math.random() * 10000;
          setTimeout(() => {
            this.getEvent(this.eventId);
          }, randomTimeout);
        }
      });
      socket.on('event:reset', (message) => {
        const {
          event: { id },
        } = message;
        if (this.event && this.event.id === id) {
          console.log('event reset', id);
          this.authSignOut();
          const randomTimeout = Math.random() * 2500;
          setTimeout(() => {
            this.initEvent(false);
          }, randomTimeout);
        }
      });
      socket.on('tech-support-reply', (message) => {
        this.appendTechSupportMessage(message);
      });
      socket.on('chat-room-message', (message) => {
        this.appendChatRoomMessage(message);
      });
      socket.on('disconnect', () => { });
      socket.on('quest:update', (message) => {
        const randomTimeout = Math.random() * 5000;
        setTimeout(() => {
          eventBus.$emit('quest:update', message);
        }, randomTimeout);
      });
      socket.on('instance:update', (message) => {
        const randomTimeout = Math.random() * 5000;
        setTimeout(() => {
          eventBus.$emit('instance:update', message);
        }, randomTimeout);
      });
      socket.on('game:update', (message) => {
        const randomTimeout = Math.random() * 5000;
        setTimeout(() => {
          eventBus.$emit('game:update', message);
        }, randomTimeout);
      });
      socket.on('challenge:update', (message) => {
        const { from, to, event, id: challengeId } = message;
        if (this.event != null && this.event.id === event && (this.userId === from || this.userId === to)) {
          eventBus.$emit('standings:update');
          console.log('challenge:update', { from, to, event });
          this.fetchChallenge(challengeId);
        }
      });
    },
    closeIOSocket() {
      if (this.socket) {
        this.socket.close();
        this.socket = null;
      }
    },
    initTimer() {
      this.nowTimer = setInterval(() => {
        this.now = new Date();
      }, 1000);
    },

    initChallengeCheck() {
      if (this.$route.meta.isEventSupportPvp === true) {

        if (this.checkChallengeTimer != null) {
          clearInterval(this.checkChallengeTimer);
          this.checkChallengeTimer = null;
        }
        this.checkChallengeTimer = setInterval(() => {
          console.log('checkChallengeTimer tick');
          this.checkActiveChallenge();
        }, 5 * 1000);
      }
    },

    async validationFormSubmit() {
      try {
        const response = await this.authSignUp({
          invitation: {
            secret: this.invitationCode,
            data: this.invitationFormData,
          },
        });
        console.log(response);
      } catch (err) {
        console.log(err);
      }
    },

    initInvitationFormData() {
      const optionsFields = _.filter(
        this.invitationFields,
        (field) => field.type === 'options'
      );
      const optionsFieldsWithOneOption = optionsFields.filter((field) => {
        const options = _.get(field, 'options') || [];
        const isOneOptionField = options.length === 1;
        return isOneOptionField;
      });
      optionsFieldsWithOneOption.forEach((field) => {
        if (this.invitationFormData[field.id] == null) {
          this.invitationFormData[field.id] = field.options[0].value;
        }
      });
    },
  },

  data: () => ({
    socket: null,
    now: new Date(),
    nowTimer: null,
    checkChallengeTimer: null,
    alertMessage: null,
    alertTitle: null,
    alertDialog: false,
    invitationDialog: false,
    invitationWrongDialog: false,
    invitationCode: null,
    invitationFormData: {},
  }),
};
