<template>
  <div>
    <v-menu v-if="user" offset-y open-on-hover>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          target="_blank"
          text
          v-bind="attrs"
          v-on="on"
          class="primary darken-1"
        >
          <span class="mr-2">{{ userName }}</span>
        </v-btn>
      </template>
      <template>
        <v-sheet dark color="primary darken-3 align-center justify-center pa-2">
          <v-img
            v-if="false && userImage"
            contain
            :src="userImage"
            max-width="240"
          />
          <v-btn
            text
            color="white"
            v-if="editableProfile"
            class="mt-2"
            @click="$emit('edit-profile')"
            >Редактировать профиль</v-btn
          >
          <br />
          <v-btn text color="white" @click="$emit('signout')">Выйти</v-btn>
        </v-sheet>
      </template>
    </v-menu>
    <template v-else>
      <v-btn color="primary darken-1" v-if="signin" @click="$emit('signin')"
        >Вход</v-btn
      >
      <v-btn color="primary" class="ml-2" v-if="signup" @click="$emit('signup')"
        >Регистрация</v-btn
      >
    </template>
  </div>
</template>

<script>
import get from "lodash/get";
import themeMixin from "@/mixins/eventTheme";

export default {
  props: ["user", "signin", "signup", "editableProfile"],
  mixins: [themeMixin],
  computed: {
    userName() {
      return (
        get(this.user, "profile.name") ||
        get(this.user, "profile.email") ||
        get(this.user, "username")
      );
    },
    userImage() {
      return get(this.user, "profile.thumbnail");
    },
  },
};
</script>