<template>
  <v-dialog
    :value="value"
    @input="
      $emit('input', $event);
      clearState();
    "
    :max-width="multiline ? 720 : 400"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-1"> Ваш ответ </v-card-title>

      <v-card-text>
        <v-select
          v-if="isOptions"
          v-model="answer"
          :items="options"
          label="Варианты ответа"
          clearable
        />
        <template v-if="isMultiAnswers">
          <v-text-field
            v-for="group in multiAnswersGroups"
            :key="group._id"
            :label="group.title"
            :placeholder="group.description || group.title"
            :value="
              multiAnswer[group._id] ||
              (bestMultiAnswersByAnswerGroup[group._id] &&
                bestMultiAnswersByAnswerGroup[group._id].answer)
            "
            @input="multiAnswer = { ...multiAnswer, [group._id]: $event }"
            :append-icon="
              bestMultiAnswersByAnswerGroup[group._id] &&
              bestMultiAnswersByAnswerGroup[group._id].answer ===
                multiAnswer[group._id]
                ? 'mdi-check'
                : 'mdi-help'
            "
          />
        </template>
        <template v-else>
          <v-text-field v-model="answer" v-if="!multiline" />
          <v-textarea
            auto-grow
            v-model="answer"
            v-else
            style="min-width: 600px"
          />
        </template>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" text @click="onCancel"> Отмена </v-btn>

        <v-btn color="primary" :disabled="!isValid" text @click="onSubmit">
          Отправить ответ
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="confirmDialog"
      :max-width="multiline ? 720 : 400"
      persistent
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          {{ confirmMessage }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="secondary" text @click="onUnconfirm"> Нет </v-btn>

          <v-btn color="primary" text @click="onConfirm"> Да </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import map from "lodash/map";
import _ from "lodash";

export default {
  name: "QuestTaskAnswerDialog",
  props: ["value", "gameTask", "questTask", "multiline"],
  data() {
    return {
      answer: "",
      multiAnswer: {},
      confirmDialog: false,
      confirmMessage: "Вы уверены?",
      confirmCallback: () => {},
    };
  },
  methods: {
    clearState() {
      this.answer = "";
      this.multiAnswer = {};
    },
    displayConfirm() {
      this.confirmDialog = true;
      this.confirmMessage = "Вы уверены?";
    },
    onCancel() {
      this.confirmCallback = () => {
        this.$emit("input", false);
        this.clearState();
      };
      this.displayConfirm();
    },
    onSubmit() {
      this.confirmCallback = () => {
        this.$emit(
          "answer",
          this.isMultiAnswers ? this.multiAnswer : this.answer
        );
        this.clearState();
      };
      this.displayConfirm();
    },
    onConfirm() {
      if (this.confirmCallback != null) {
        this.confirmCallback();
        this.confirmCallback = null;
      }
    },
    onUnconfirm() {
      this.confirmDialog = false;
    },
    initMultiAnswer() {
      if (this.bestMultiAnswersByAnswerGroup == null) return;
      Object.assign(
        this.multiAnswer,
        Object.keys(this.bestMultiAnswersByAnswerGroup).reduce(
          (acc, groupId) => {
            acc[groupId] = this.bestMultiAnswersByAnswerGroup[groupId].answer;
            return acc;
          },
          {}
        )
      );
    },
  },
  computed: {
    isValid() {
      if (this.isMultiAnswers) {
        for (const group of this.multiAnswersGroups) {
          const key = group._id;
          const value = this.multiAnswer[key];
          if (value == null || value.trim() === "") {
            return false;
          }
        }
        return true;
      } else {
        return !!this.answer.trim();
      }
    },
    questTaskKind() {
      return this.questTask && this.questTask.kind;
    },
    isOptions() {
      return this.questTaskKind === "options";
    },
    isMultiAnswers() {
      return this.questTaskKind === "multianswers";
    },
    multiAnswersGroups() {
      return _.map(_.get(this.questTask, "multiAnswersGroups"), (item) => item);
    },
    bestMultiAnswersByAnswerGroup() {
      if (!this.isMultiAnswers) return null;
      return _.reduce(
        _.filter(
          _.get(this.gameTask, "answers"),
          (item) => item.type === "multi"
        ),
        (acc, item) => {
          const { checkResult } = item;
          Object.keys(checkResult).forEach((groupId) => {
            const { points } = checkResult[groupId];
            if (acc[groupId] == null || acc[groupId].points < points) {
              acc[groupId] = checkResult[groupId];
            }
          });
          return acc;
        },
        {}
      );
    },
    options() {
      return map(
        this.questTask && this.questTask.options,
        (item) => item.label
      );
    },
  },
  watch: {
    bestMultiAnswersByAnswerGroup() {
      this.initMultiAnswer();
    },
  },
  created() {
    this.initMultiAnswer();
  },
  beforeMount() {
    this.initMultiAnswer();
  },
};
</script>