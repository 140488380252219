<template>
  <v-dialog
    v-if="pendingHint"
    :value="value"
    @input="$emit('input', $event)"
    :max-width="300"
  >
    <v-card>
      <v-card-title class="text-subtitle-1">
        Хотите получить подсказку?
      </v-card-title>

      <v-card-text v-if="points > 0">
        Стоимость подсказки: {{ points }} балл(ов)
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="secondary" text @click="$emit('input', false)">
          Отмена
        </v-btn>

        <v-btn color="primary" text @click="$emit('hint-confirm')"> Да </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import map from "lodash/map";

export default {
  name: "QuestTaskHintDialog",
  props: ["value", "gameTask", "questTask", "showPrice"],
  data() {
    return {};
  },
  computed: {
    questTaskKind() {
      return this.questTask && this.questTask.kind;
    },
    isOptions() {
      return this.questTaskKind === "options";
    },
    pendingHint() {
      const { hintsUsed = 0 } = this.gameTask;
      const hints = map(this.questTask.hints, (item) => item);
      if (hintsUsed + 1 > hints.length) {
        return null;
      }
      return hints[hintsUsed];
    },
    points() {
      return this.pendingHint && this.pendingHint.points;
    },
  },
};
</script>