var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:(Object.assign({}, (_vm.leader
      ? {
          border: 'solid 5px #FFBF00',
          cornerRadius: '16px',
          backgroundColor: '#FFBF00',
          fontWeight: 'bold',
        }
      : {}))),attrs:{"elevation":4}},[_c('v-img',{attrs:{"src":_vm.url,"aspect-ratio":_vm.aspectRatio},on:{"dblclick":_vm.onDoubleClick}}),(_vm.author)?_c('v-card-title',{staticClass:"gallery-title",style:(Object.assign({}, (_vm.leader
        ? {
            backgroundColor: '#fff7d2',
          }
        : {})))},[_vm._v(_vm._s(_vm.author))]):_vm._e(),(_vm.likeable || _vm.total)?_c('v-card-actions',{style:(Object.assign({}, (_vm.leader
        ? {
            backgroundColor: '#fff7d2',
          }
        : {})))},[(_vm.bonus)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v("mdi-gift-outline")]),_c('span',_vm._g(_vm._b({staticClass:"red--text ml-4 mr-4"},'span',attrs,false),on),[_vm._v(_vm._s(_vm.bonus))])]}}],null,false,1608695041)},[_c('span',[_vm._v("Бонусные баллы")])]):_vm._e(),(_vm.likeable)?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"red","text":"","fab":"","small":""},on:{"click":_vm.onDoubleClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.isLikedByUser ? "mdi-heart" : "mdi-heart-outline"))])],1)]}}],null,false,892039097)},[_c('span',[_vm._v(_vm._s(_vm.isLikedByUser ? "Убрать лайк" : "Поставить лайк!"))])]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-heart")]),(_vm.total)?_c('span',{staticClass:"red--text ml-2"},[_vm._v(_vm._s(_vm.total))]):_vm._e(),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","text":"","fab":"","small":""},on:{"click":_vm.onZoomClick}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,4033387680)},[_c('span',[_vm._v("Увеличить")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }