import data from './data';
import get from 'lodash/get';
import _ from 'lodash';

const mapHint = (hint) => {
  const l = (get(hint, 'localization') || []).find((l) => l.language === 'ru');
  const description = get(l, 'description');
  const image = get(l, 'image') || hint.image;
  return {
    description,
    image,
  };
};

const mapTask = (task) => {
  const l = (get(task, 'localization') || []).find((l) => l.language === 'ru');
  const {
    _id: id,
    name: title,
    acceptUniversalAnswer,
    universalAnswerPoints,
  } = task;
  const correctAnswerMessage =
    get(l, 'correctAnswerMessage') || task.correctAnswerMessage;
  const wrongAnswerMessage =
    get(l, 'wrongAnswerMessage') || task.wrongAnswerMessage;
  const description = get(l, 'description') || task.description;
  const fact = get(l, 'fact') || task.fact;
  const factImage = get(l, 'factImage') || task.factImage;
  const image = get(l, 'image') || task.image;
  const hints = (get(task, 'hints') || []).map(mapHint);
  const kind = task.kind;
  const multiAnswersGroups = _.map(
    _.get(task, 'multiAnswersGroups'),
    (item) => item
  );
  return {
    id,
    title,
    correctAnswerMessage,
    wrongAnswerMessage,
    description,
    image: `https://admin.your-quest.com/api/public/image/${image}?width=640`,
    kind,
    acceptUniversalAnswer,
    universalAnswerPoints,
    multiAnswersGroups,
    ...(fact ? { fact: { fact, image: factImage } } : null),
    hints,
  };
};

export default {
  namespaced: true,
  state: { quest: data },
  getters: {
    quest: (state) => {
      const l = (get(state, 'quest.localization') || []).find(
        (l) => l.language === 'ru'
      );
      const id = state.quest._id;
      const title = get(l, 'title') || state.quest.name;
      const image = get(l, 'image') || state.quest.image;
      const description = get(l, 'description') || state.quest.description;
      const finalMessage = get(l, 'finalMessage') || state.quest.finalMessage;
      return {
        id,
        title,
        image: `https://admin.your-quest.com/api/public/image/${image}?width=640`,
        description,
        finalMessage,
      };
    },
    tasks: (state) => {
      const tasks = get(state, 'quest.tasks') || [];
      return tasks.map(mapTask);
    },
    currentTask: (state) => {
      const tasks = get(state, 'quest.tasks') || [];
      return tasks.length === 0 ? null : mapTask(tasks[0]);
    },
  },
  mutations: {},
  actions: {},
};
