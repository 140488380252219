import axios from 'axios';
import DefaultStorage from '../utils/storage';
import _ from 'lodash';

const STORAGE_TOKEN_KEY = 'auth-token';

class ApiClient {
  constructor() {
    this.token = DefaultStorage.getItem(STORAGE_TOKEN_KEY);
    const baseURL = process.env.VUE_APP_API_ROOT || '/api';
    this.axios = axios.create({
      baseURL,
      timeout: 30000,
      ...(process.env.VUE_APP_API_ROOT ? { crossdomain: true } : {}),
      headers: {
        ...(this.token ? { Authorization: `Bearer ${this.token}` } : {}),
      },
    });
  }
  setToken(token) {
    this.token = token;
    DefaultStorage.setItem(STORAGE_TOKEN_KEY, token);
    if (token) {
      this.axios.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${this.token}`;
    } else {
      delete this.axios.defaults.headers.common['Authorization'];
    }
  }
  async getEvent(eventIdOrSlug) {
    const url = `/event/${eventIdOrSlug}`;
    const response = await this.axios.get(url, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return response.data;
  }
  async getMyProfile() {
    if (this.token == null) {
      return null;
    }
    const url = '/user/me';
    const { data } = await this.axios.get(url, {
      headers: { Authorization: `Bearer ${this.token}` },
    });
    return { ...data, token: this.token };
  }
  async updateMyProfile(payload) {
    try {
      const url = '/user/me';
      const { data } = await this.axios.put(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async postChallenge(payload) {
    try {
      const url = '/challenge';
      const { data } = await this.axios.post(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async cancelChallenge(id) {
    try {
      const url = `/challenge/${id}/cancel`;
      const { data } = await this.axios.put(url, null, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async acceptChallenge(id) {
    try {
      const url = `/challenge/${id}/accept`;
      const { data } = await this.axios.put(url, null, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async activateChallenge(id) {
    try {
      const url = `/challenge/${id}/activate`;
      const { data } = await this.axios.put(url, null, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async postChallengeChatMessage({ id, message }) {
    try {
      const url = `/challenge/${id}/chat-message`;
      const { data } = await this.axios.post(url, {
        message
      }, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async runChallengeCommand({ id, command, params }) {
    try {
      const url = `/challenge/${id}/command`;
      const payload = {
        command,
        params
      }
      const { data } = await this.axios.put(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async fetchChallenge(id) {
    try {
      const url = `/challenge/${id}`;
      const { data } = await this.axios.get(url, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async checkActiveChallenge() {
    try {
      const url = `/challenge/active`;
      const { data } = await this.axios.get(url, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async updateMyAvatar(file) {
    try {
      const url = '/user/avatar';
      const form = new FormData();
      form.append('avatar', file);
      const { data } = await this.axios.put(url, form, {
        headers: {
          Authorization: `Bearer ${this.token}`,
          'Content-Type': 'multipart/form-data'
        },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async authSignIn(payload) {
    try {
      const url = '/auth/signin';
      const { data } = await this.axios.post(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      this.setToken(data.token);
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async authEmailSignIn({ email, scope }) {
    try {
      const url = '/auth/email-signin';
      const { data } = await this.axios.post(url, { email, scope });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async authSignUp(payload) {
    try {
      const url = '/auth/signup';
      const { data } = await this.axios.post(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      this.setToken(data.token);
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async runCommand({ scope, itemId, command, params }) {
    try {
      const url = '/cmd';
      const payload = { scope, itemId, command, params };
      const { data } = await this.axios.post(url, payload, {
        headers: { Authorization: `Bearer ${this.token}` },
      });
      return data;
    } catch (err) {
      throw (err.response && err.response.data) || err;
    }
  }
  async uploadFileToS3Storage(file, uploadUrl, headers) {
    return axios.put(uploadUrl, file, {
      headers: _.omit(headers, ['host', 'Host']),
      crossdomain: true,
    });
  }
  async postLike(id, like, event, exclusive = false) {
    return this.axios.post('/event/like', {
      id,
      like,
      event,
      exclusive,
    });
  }
  async getEventLikes(eventIdOrSlug) {
    const url = `/event/${eventIdOrSlug}/likes`;
    return this.axios.get(url);
  }
  async getEventLikesWithAuthors(eventIdOrSlug, ids) {
    const url = `/event/${eventIdOrSlug}/likes-with-authors`;
    return this.axios.post(url, { ids });
  }
  async getEventChatMessages(eventIdOrSlug) {
    const url = `/event/${eventIdOrSlug}/chat`;
    return this.axios.get(url);
  }

  async postEventInvitation(eventIdOrSlug, secret) {
    const url = `/event/${eventIdOrSlug}/invitation`;
    return this.axios.post(url, {
      secret,
    });
  }
  async getGratitudeSummary() {
    const url = `/gratitude/summary`;
    return this.axios.get(url);
  }
  async getGratitudeStats() {
    const url = `/gratitude/stats`;
    return this.axios.get(url);
  }
  async getGratitudeUsers() {
    const url = `/gratitude/users`;
    return this.axios.get(url);
  }
  async getGratitudeMessages({ from = null, to = null }) {
    const query = {
      ...(from ? { from } : {}),
      ...(to ? { to } : {}),
    };
    const url = '/gratitude';
    return this.axios.get(url, { params: query });
  }
  async postGratitudeMessage({ to, text, meta }) {
    const payload = {
      to,
      text,
      meta,
    };
    const url = '/gratitude';
    return this.axios.post(url, payload);
  }
  async getShopProducts() {
    const url = `/shop/products`;
    return this.axios.get(url);
  }
  async getShopBalanceRecords() {
    const url = '/shop/user-balance';
    return this.axios.get(url);
  }
  async getShopProductPurchases() {
    const url = '/shop/user-product-purchases';
    return this.axios.get(url);
  }
  async getShopUsers() {
    const url = '/shop/users';
    return this.axios.get(url);
  }
  async postShopProductPurchase({ product, quantity }) {
    const url = '/shop/product-purchase';
    const payload = {
      product,
      quantity,
    };
    return this.axios.post(url, payload);
  }
  async postShopBalanceTransfer({ to, amount }) {
    const url = '/shop/balance-transfer';
    const payload = {
      to,
      amount,
    };
    return this.axios.post(url, payload);
  }
}

export default new ApiClient();
