<template>
  <div>
    <div
      v-for="(task, i) in tasks"
      :key="i" class="d-flex"
      :class="{'mb-2': i < tasks.length - 1}">
      <v-img max-width="120" cover :src="task.image" />
      <div class="d-flex flex-column pa-4">
        <h2 style="font-weight: 300;">{{ task.title }}</h2>
        <div class="mv-2">{{ task.description }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      tasks: 'event/quest/tasks',
    }),
  },
}
</script>