<template>
  <div>
    <v-fade-transition :group="true" :hideOnLeave="true">
      <div
        class="d-flex"
        v-if="!isMobile && layout === 'horizontal'"
        :key="'horizontal-items'"
      >
        <template v-for="item in visibleContent">
          <v-sheet
            v-if="visibleItems[item._id]"
            :key="`content-${item._id}`"
            class="mb-2 mx-2"
            style="background: transparent"
          >
            <item
              :params="item.params"
              :type="item.type"
              :item-id="item._id"
              :mapping="mapping"
            />
          </v-sheet>
        </template>
      </div>
      <template v-for="item in visibleContent" v-else>
        <v-sheet
          v-if="visibleItems[item._id]"
          :key="`content-${item._id}`"
          class="mb-2"
          style="background: transparent"
        >
          <item
            :params="item.params"
            :type="item.type"
            :item-id="item._id"
            :mapping="mapping"
          />
        </v-sheet>
      </template>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Item from './Content/Item';
import reduce from 'lodash/reduce';
import last from 'lodash/last';
import filter from 'lodash/filter';
import themeMixin from '@/mixins/eventTheme';

export default {
  name: 'AppEventContent',
  props: ['content', 'mapping', 'layout'],
  mixins: [themeMixin],
  components: {
    Item,
  },
  methods: {
    isItemVisible(item) {
      const { mode = 'all' } = item;

      switch (mode) {
        case 'not-auth':
          return this.user == null;
        case 'auth-only':
          return this.user != null;
        default:
          return true;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: 'event/user',
    }),
    visibleItems() {
      const { visibleContent: items, user } = this;
      return reduce(
        items,
        (acc, item) => {
          const { mode = 'all' } = item;
          if (mode === 'not-auth' && !user) {
            acc[item._id] = true;
          } else if (mode === 'auth-only' && user) {
            acc[item._id] = true;
          } else if (mode === 'all') {
            acc[item._id] = true;
          }
          return acc;
        },
        {}
      );
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    visibleContent() {
      const visibleItems = filter(this.content, (item) => item.visible);
      return reduce(
        visibleItems,
        (acc, item) => {
          try {
            if (item.type === 'image') {
              // eslint-disable-next-line
              const prevItem = last(acc);
              let galleryItem = null;
              if (prevItem && prevItem.type === 'image-gallery') {
                galleryItem = prevItem;
              } else {
                galleryItem = {
                  type: 'image-gallery',
                  mode: 'all',
                  params: {
                    images: [],
                  },
                };
                acc.push(galleryItem);
              }
              galleryItem.params.images.push(item);
            } else {
              acc.push(item);
            }
          } catch (err) {
            console.error(err);
          }

          return acc;
        },
        []
      );
    },
  },
};
</script>
