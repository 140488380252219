import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      user: 'event/user',
      isAdmin: 'event/isAdmin',
    }),
  },
};
