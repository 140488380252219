<template>
  <div v-if="selectedChallenge">
    <div v-if="status === 'ready'">
      <div class="ma-4 pt-4">
        <div class="d-flex align-center pb-4" v-if="!isMyChallenge">
          <v-avatar class="ma-2" color="primary">
            <v-img
              v-if="selectedChallenge.from.thumbnail"
              :src="selectedChallenge.from.thumbnail"
              :alt="selectedChallenge.from.name"
            />
            <span v-else class="white--text text-h6">{{
              getInitials(selectedChallenge.from.name)
            }}</span>
          </v-avatar>
          <h3 class="pl-2">{{ selectedChallenge.from.name }}</h3>
        </div>
        <div class="d-flex align-center pb-4" v-else>
          <v-avatar class="ma-2" color="primary">
            <v-img
              v-if="selectedChallenge.to.thumbnail"
              :src="selectedChallenge.to.thumbnail"
              :alt="selectedChallenge.to.name"
            />
            <span v-else class="white--text text-h6">{{
              getInitials(selectedChallenge.to.name)
            }}</span>
          </v-avatar>
          <h3 class="pl-2">{{ selectedChallenge.to.name }}</h3>
        </div>
      </div>
      <div class="full-width ma-4">
        <div
          v-for="(msg, index) in selectedChallenge.chat"
          :key="index"
          class="mb-1"
        >
          <strong>{{ msg.from === userId ? "Я" : "Соперник" }}:</strong>
          {{ msg.message }}
        </div>
        <v-text-field
          class="mt-2"
          dense
          label="Введите ваше сообщение и нажмите Enter"
          @change="onChatMessageChange($event)"
          v-model="chatMessage"
          outlined
        ></v-text-field>
      </div>
      <div class="ma-4 pb-4 d-flex justify-center">
        <template>
          <v-btn @click="onCancelChallenge" color="error" :disabled="isFinished"
            >{{
              !isFinished && selectedChallengeRemaining
                ? `${selectedChallengeRemaining} | `
                : ""
            }}Отменить вызов</v-btn
          >
        </template>
        <v-spacer></v-spacer>
        <template v-if="!isMyChallenge">
          <v-btn
            @click="onAcceptChallenge"
            color="primary"
            class="ml-4"
            :disabled="isFinished"
            >{{
              !isFinished && selectedChallengeRemaining
                ? `${selectedChallengeRemaining} | `
                : ""
            }}Принять вызов</v-btn
          >
        </template>
      </div>
    </div>
    <div v-if="status === 'pending'">
      <div class="ma-4 pt-4">
        <div class="d-flex align-center pb-4" v-if="!isMyChallenge">
          <v-avatar class="ma-2" color="primary">
            <v-img
              v-if="selectedChallenge.from.thumbnail"
              :src="selectedChallenge.from.thumbnail"
              :alt="selectedChallenge.from.name"
            />
            <span v-else class="white--text text-h6">{{
              getInitials(selectedChallenge.from.name)
            }}</span>
          </v-avatar>
          <h3 class="pl-2">{{ selectedChallenge.from.name }}</h3>
        </div>
        <div class="d-flex align-center pb-4" v-else>
          <v-avatar class="ma-2" color="primary">
            <v-img
              v-if="selectedChallenge.to.thumbnail"
              :src="selectedChallenge.to.thumbnail"
              :alt="selectedChallenge.to.name"
            />
            <span v-else class="white--text text-h6">{{
              getInitials(selectedChallenge.to.name)
            }}</span>
          </v-avatar>
          <h3 class="pl-2">{{ selectedChallenge.to.name }}</h3>
        </div>
      </div>
      <div class="full-width ma-4">
        <div
          v-for="(msg, index) in selectedChallenge.chat"
          :key="index"
          class="mb-1"
        >
          <strong>{{ msg.from === userId ? "Я" : "Соперник" }}:</strong>
          {{ msg.message }}
        </div>
        <v-text-field
          class="mt-2"
          dense
          label="Введите ваше сообщение и нажмите Enter"
          @change="onChatMessageChange($event)"
          v-model="chatMessage"
          outlined
        ></v-text-field>
      </div>
      <div class="ma-4 pb-4 d-flex justify-center">
        <template>
          <v-btn @click="onActivateChallenge" color="error"
            >Отправить вызов</v-btn
          >
        </template>
      </div>
    </div>
    <div v-if="status === 'expired'">
      <v-card>
        <v-card-title> Время ожидания истекло. Вызов отменен. </v-card-title>
      </v-card>
    </div>
    <div v-if="status === 'cancelled'">
      <v-card>
        <v-card-title> Вызов был отменен. </v-card-title>
      </v-card>
    </div>
    <template
      v-else-if="
        status === 'accepted' || status === 'resolved' || status === 'cancelled'
      "
    >
      <sea-battle
        :challenge="selectedChallenge"
        :is-my="isMyChallenge"
        @command="onChallengeCommand($event)"
        v-if="gameType === 'sea-battle'"
      />
      <god-of-random
        :challenge="selectedChallenge"
        :is-my="isMyChallenge"
        :user-id="userId"
        :from-user-id="fromUserId"
        :to-user-id="toUserId"
        :remaining="selectedChallengeRemaining"
        @command="onChallengeCommand($event)"
        v-if="gameType === 'god-of-random'"
      />
      <air-battle
        :challenge="selectedChallenge"
        :is-my="isMyChallenge"
        :user-id="userId"
        :from-user-id="fromUserId"
        :to-user-id="toUserId"
        :remaining="selectedChallengeRemaining"
        @command="onChallengeCommand($event)"
        v-if="gameType === 'air-battle'"
      />
    </template>
  </div>
</template>

<script>
import SeaBattle from "../sea-battle/Index.vue";
import AirBattle from "../air-battle/Index.vue";
import GodOfRandom from "../god-of-random/Index.vue";

import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  name: "Challenge",
  components: {
    SeaBattle,
    GodOfRandom,
    AirBattle,
  },
  data() {
    return {
      getInitials,
      chatMessage: "",
    };
  },
  computed: {
    ...mapGetters({
      selectedChallenge: "event/challenge/selectedChallenge",
      selectedChallengeRemaining: "event/challenge/selectedChallengeRemaining",
      user: "event/user",
    }),
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.selectedChallenge.status
      );
    },
    gameType() {
      return _.get(this.selectedChallenge, "gameId");
    },
    status() {
      return _.get(this.selectedChallenge, "status");
    },
    userId() {
      return _.get(this.user, "id");
    },
    fromUserId() {
      return _.get(this.selectedChallenge, "from.id");
    },
    toUserId() {
      return _.get(this.selectedChallenge, "to.id");
    },
    isMyChallenge() {
      return this.fromUserId === this.userId;
    },
  },
  methods: {
    ...mapActions({
      cancelChallenge: "event/challenge/cancelChallenge",
      acceptChallenge: "event/challenge/acceptChallenge",
      activateChallenge: "event/challenge/activateChallenge",
      runChallengeCommand: "event/challenge/runChallengeCommand",
      postChallengeChatMessage: "event/challenge/postChallengeChatMessage",
    }),
    onCancelChallenge() {
      this.cancelChallenge(this.selectedChallenge._id);
    },
    onAcceptChallenge() {
      this.acceptChallenge(this.selectedChallenge._id);
    },
    onActivateChallenge() {
      this.activateChallenge(this.selectedChallenge._id);
    },
    onChallengeCommand({ command, params }) {
      this.runChallengeCommand({
        id: this.selectedChallenge._id,
        command,
        params,
      });
    },
    async onChatMessageChange() {
      const message = this.chatMessage.trim();
      if (!message) return;
      this.chatMessage = "";
      this.postChallengeChatMessage({
        id: this.selectedChallenge._id,
        message,
      });
    },
  },
};
</script>