<template>
  <v-card>
    <v-card-title>Воздушный бой c {{ opponentName }}</v-card-title>
    <v-card-actions v-if="status === 'deployment' && !ready" class="pl-4">
      <v-btn v-on:click="startGame" color="primary"
        >{{ remaining ? `${remaining} :` : "" }} Я готов
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-card-text>
      <div class="ab__content-battle">
        <div class="ab__content-bf-wrap">
          <div class="ab__content-bf-player">
            <template v-if="status === 'deployment' && !ready">
              <span class="ab__content-bf-player-name"
                >Расставьте ваше игровое поле</span
              >
              <span class="ab__content-bf-player-score">{{ remaining }}</span>
            </template>
            <template v-else>
              <span class="ab__content-bf-player-name">{{ playerName }}</span>
              <span
                class="ab__content-bf-player-score"
                v-if="status !== 'deployment'"
                >Баллы {{ score }}</span
              >
            </template>
          </div>
          <b-start-bf
            :engine="engine"
            :store="store"
            v-if="status === 'deployment' && ready === false"
            @start-ships-update="onStartShipsUpdate"
          ></b-start-bf>
          <div v-if="status === 'deployment' && !ready">
            <ul>
              <li>
                Правила расстановки ваших самолетов - классические: только
                прямые, не соприкасаются
              </li>
              <li>
                Чтобы поменять ориентацию самолета - два коротких клика на борт
              </li>
              <li>
                Поворот борта осуществляется по крайней левой/верхней клетке
              </li>
              <li>
                Если поворот невозможен из-за соприкосновения с другим - борт не
                повернется
              </li>
              <li>
                Перетаскивать самолеты до старта можно удержанием клика мыши и
                перемещением по игровому полю. Удачи!
              </li>
            </ul>
          </div>
          <b-my-bf
            :engine="engine"
            :store="store"
            :lost="status === 'done' && score < opponentScore"
            v-if="
              status === 'in-progress' ||
              (status === 'deployment' && ready === true) ||
              status === 'done'
            "
          ></b-my-bf>
        </div>
        <div
          class="ab__content-bf-turn d-flex align-center text-center justify-center"
          v-if="!(status === 'deployment' && !ready)"
        >
          <template v-if="status === 'deployment'">
            <template>
              <img :src="require('./assets/ab-turn-right.png')" />
              <h4>Ждём соперника {{ remaining ? `${remaining}` : "" }}</h4>
            </template>
          </template>
          <template v-if="status === 'in-progress'">
            <template v-if="active">
              <img :src="require('./assets/ab-turn-right.png')" />
              <h4>Ваш ход {{ remaining ? `: ${remaining}` : "" }}</h4>
            </template>
            <template v-else>
              <img :src="require('./assets/ab-turn-left.png')" />
              <h4>Ход соперника {{ remaining ? `: ${remaining}` : "" }}</h4>
            </template>
          </template>
          <template v-if="status === 'done'">
            <template v-if="score > opponentScore">
              <img :src="require('./assets/ab-turn-right.png')" />
              <h4>Вы победили</h4>
            </template>
            <template v-else>
              <img :src="require('./assets/ab-turn-left.png')" />
              <h4>Победил соперник</h4>
            </template>
          </template>
        </div>
        <div
          class="ab__content-bf-wrap"
          v-if="!(status === 'deployment' && !ready)"
        >
          <div class="ab__content-bf-player">
            <span class="ab__content-bf-player-name">
              <template v-if="status !== 'deployment'">
                {{ opponentName }}
              </template>
            </span>
            <span
              class="ab__content-bf-player-score"
              v-if="status !== 'deployment'"
              >Баллы {{ opponentScore }}</span
            >
          </div>
          <div v-if="status === 'deployment' && ready">
            <div class="ab__content-bf bf-await"></div>
            <div class="ab__content-status-wrap">
              <div class="ab__content-bf-status">
                <h4>Ваш соперник расставляет самолёты на игровом поле.</h4>
                <p>Игра скоро начнётся, подождите немного.</p>
              </div>
            </div>
          </div>
          <div
            v-else-if="status === 'deployment' && !ready"
            class="d-flex align-center justify-center"
          ></div>
          <b-rival-bf
            v-else-if="status === 'in-progress' || status === 'done'"
            :engine="engine"
            :store="store"
            :lost="status === 'done' && opponentScore < score"
            @attack="onAttack"
          />
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import StartingBattlefield from "./StartingBattlefield.vue";
import Battlefield from "./Battlefield.vue";
import RivalBattlefield from "./RivalBattlefield.vue";

export default {
  name: "Game",
  props: [
    "engine",
    "status",
    "ready",
    "store",
    "active",
    "score",
    "opponent-score",
    "opponent-name",
    "player-name",
    "remaining",
  ],
  data() {
    return {
      msg: "Воздушный бой.",
      showStartBattlefield: true,
      showMyBattlefield: false,
    };
  },
  methods: {
    startGame() {
      this.$emit("ready");
    },
    onStartShipsUpdate(ships) {
      this.$emit("start-ships-update", ships);
    },
    onAttack({ x, y }) {
      this.$emit("attack", { x, y });
    },
  },
  components: {
    "b-start-bf": StartingBattlefield,
    "b-rival-bf": RivalBattlefield,
    "b-my-bf": Battlefield,
  },
  mounted() {
    window.addEventListener("click", () => {
      console.log("dispatchEvent hideDrop");
      this.$root.$emit("hideDrop");
    });
  },
};
</script>

<style scoped>
.bf-await {
  background: url(./assets/ab-bf-enemy.png) no-repeat;
  background-size: cover;
}
</style>