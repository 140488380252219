var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-center justify-center text-center",staticStyle:{"position":"relative"}},[_c('div',{style:({ opacity: _vm.lost ? '0.25' : '1' })},[_c('b-markers'),_c('div',{staticClass:"b-battlefield b-canvas-wrapper"},[_c('div',{staticClass:"b-grid"},[_c('table',{staticClass:"b-grid-table"},[_c('tbody',[_c('tr',_vm._l((_vm.items),function(gridItem){return _c('td',{key:gridItem.index,staticClass:"b-grid-item",class:[
                  gridItem.classes,
                  {
                    'bb-grid-item--miss': gridItem.miss,
                    'bb-grid-item--miss-auto': gridItem.missAuto,
                    'bb-grid-item--hit': gridItem.hit,
                    'bb-grid-item--done': gridItem.done,
                  } ]},[_c('div',{staticClass:"b-grid-item-content d-flex align-center justify-center",class:{
                    'ship-alive': gridItem.ship && !gridItem.played,
                    'ship-dead': gridItem.ship && gridItem.played,
                  }},[_c('span',{staticClass:"b-grid-item-status"}),(gridItem.ship && !gridItem.played)?_c('img',{staticStyle:{"width":"50%","height":"50%"},attrs:{"src":require('./assets/alive-2.svg')}}):(gridItem.ship && gridItem.played)?_c('img',{staticStyle:{"width":"50%","height":"50%"},attrs:{"src":require('./assets/dead-2.svg')}}):(gridItem.miss || gridItem.missAuto)?_c('img',{staticStyle:{"width":"50%","height":"50%"},attrs:{"src":require('./assets/miss.svg')}}):(gridItem.hit)?_c('img',{staticStyle:{"width":"50%","height":"50%"},attrs:{"src":require('./assets/partial-hit.svg')}}):_vm._e()])])}),0)])])])])],1),(_vm.lost)?_c('div',{staticStyle:{"position":"absolute"}},[_c('img',{staticClass:"ab-status-lost",attrs:{"src":require('./assets/ab-status-lost.png')}})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }