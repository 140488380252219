<template>
  <div
    class="pt-3 pl-3 pr-3 pb-0 mb-0 tasks-selector"
    :style="{
      ...(theme.primary ? { backgroundColor: theme.primary } : null),
    }"
  >
    <v-sheet
      class="pa-3 pb-0"
      style="flex: 1"
      rounded
      elevation="3"
      color="#0008"
    >
      <v-select
        label="Выберите задание"
        dark
        :items="items"
        :value="value"
        @input="$emit('change', $event)"
        item-value="id"
        item-text="title"
      />
    </v-sheet>
  </div>
</template>

<script>
import _ from "lodash";
import ThemeMixin from "../../../../mixins/eventTheme";

export default {
  name: "QuestTasksPicker",
  props: ["groups", "selected-game-task"],
  mixins: [ThemeMixin],
  data() {
    return {};
  },
  computed: {
    value() {
      return this.selectedGameTask && this.selectedGameTask._id;
    },
    items() {
      return this.groups.reduce((acc, item) => {
        const { gameTask, questTask, enabled, childs } = item;
        const { title } = questTask;
        const id = gameTask._id;
        if (!_.isEmpty(childs)) {
          acc.push({ divider: true, title: "------" });
        }
        acc.push({
          title,
          disabled: !enabled,
          id,
        });
        if (!_.isEmpty(childs)) {
          for (const childItem of childs) {
            const { gameTask, questTask, enabled } = childItem;
            const { title } = questTask;
            const id = gameTask._id;
            acc.push({
              title: `${title}`,
              disabled: !enabled,
              id,
            });
          }
        }
        return acc;
      }, []);
    },
  },
};
</script>
