import { mapGetters, mapMutations } from "vuex";
import findIndex from "lodash/findIndex";
import themeMixin from "./eventTheme";

import filter from "lodash/filter";

export default {
  mixins: [themeMixin],
  data() {
    return {};
  },
  props: ["dark"],
  computed: {
    ...mapGetters({
      items: "event/navItems",
      activeItemId: "event/activeItemId",
      title: "event/title",
      logo: "event/logo",
      isAdmin: 'event/isAdmin',
    }),
    visibleItems() {
      return filter(this.items, (item) => !(item.visible === false) || this.isAdmin);
    },
    selectedItem() {
      return findIndex(this.items, (item) => item.id === this.activeItemId);
    },
  },
  methods: {
    ...mapMutations({
      setActiveItem: "event/activeItemId",
    }),
    onItemSelect(id) {
      this.setActiveItem(id);
    },
  },
};
