<template>
  <div v-if="items.length > 0">
    <div
      v-for="(item, index) in items"
      :key="`quest-task-content-item-${index}`"
      :class="cssClass"
    >
      <div v-if="item.type === 'text'" v-html="item.content"></div>
      <v-carousel v-if="item.type === 'slider'" height="100%" hide-delimiters>
        <v-carousel-item v-for="(sliderItem, i) in item.content" :key="i">
          <v-row class="ma-0 pa-0" style="position: relative">
            <v-img :src="sliderItem.image" v-bind="item.attrs"> </v-img>
            <div
              class="pa-4"
              style="
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%
                  background-color: #0004;
                "
              v-if="sliderItem.title || sliderItem.description"
            >
              <template>
                <h2 v-if="sliderItem.title">{{ sliderItem.title }}</h2>
                <div v-if="sliderItem.description">
                  {{ sliderItem.description }}
                </div>
              </template>
            </div>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "QuestTaskTextContent",
  props: ["value", "cssClass"],
  data() {
    return {
      items: [],
    };
  },
  created() {
    this.initItems();
  },
  watch: {
    value() {
      this.initItems();
    },
  },
  methods: {
    initItems() {
      if (_.isEmpty(this.value)) {
        this.items = [];
      }
      const r = /\[([^\]]+)\]/gi;
      const items = [];
      let isFound = false;
      let lastIndex = 0;
      do {
        const searchResult = r.exec(this.value);
        if (searchResult == null) {
          isFound = false;
          items.push({
            type: "text",
            content: this.value.substring(lastIndex),
          });
        } else {
          isFound = true;

          if (searchResult.index > 0) {
            items.push({
              type: "text",
              content: this.value.substring(lastIndex, searchResult.index),
            });
          }

          const str = searchResult[0];
          const strWithourBrackets = str.substring(1, str.length - 1);
          const [type, ...params] = strWithourBrackets.split("|");
          if (type === "slider") {
            items.push(
              params.reduce(
                (acc, item) => {
                  const isLink = item.toLowerCase().startsWith("http");
                  const isAttr = item.toLowerCase().indexOf("=") > 0;
                  if (isLink) {
                    acc.content.push({
                      image: item,
                      title: null,
                      description: null,
                    });
                  } else if (isAttr) {
                    const [name, value] = item.split("=");
                    acc.attrs[name] = value;
                  } else if (acc.content.length > 0) {
                    const lastItem = acc.content[acc.content.length - 1];
                    if (lastItem.title == null) {
                      lastItem.title = item;
                    } else if (lastItem.description == null) {
                      lastItem.description = item;
                    }
                  }
                  return acc;
                },
                { type, content: [], attrs: { "aspect-ratio": 1 } }
              )
            );
          }
        }
        lastIndex = r.lastIndex;
      } while (isFound === true);
      this.items = items;
    },
  },
};
</script>