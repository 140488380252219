<template>
  <div>
    <slot name="logo">
      <h4
        style="font-weight: 300; color: white"
        class="d-flex justify-center mb-4"
      >
        {{ title }}
      </h4>
    </slot>
    <v-divider dark />
    <v-list nav rounded color="transparent">
      <v-list-item-group :value="selectedItem">
        <v-list-item
          v-for="(item, index) in visibleItems"
          :key="index"
          @click="onItemSelect(item.id)"
        >
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="d-flex justify-center align-center"
              style="text-shadow: 1px 1px 2px black"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <slot name="drawer-bottom"></slot>
  </div>
</template>

<script>
import eventMenuMixin from "../../mixins/eventMenuMixin";
import themeMixin from "@/mixins/eventTheme";

export default {
  name: "EventMenu",
  mixins: [eventMenuMixin, themeMixin],
};
</script>
