import quest from './quest';
import chat from './chat';
import challenge from './challenge';
import moment from 'moment-timezone';
import api from '@/api';
// moment.tz.setDefault('Europe/Moscow');

import get from 'lodash/get';
import find from 'lodash/find';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import DefaultStorage from '@/utils/storage';
import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    title: null,
    logo: require('@/assets/logo.png'),
    bg: null,
    data: [],
    navItems: [],
    activeItemId: '',
    event: null,
    user: null,
    timezone: null,
    likes: {},
    userLikes: {},
    userMeta: {},
    lastChatReadTimeStamp: null,
  },
  getters: {
    navItems: (state) => state.navItems,
    theme: (state) => get(state.event, 'theme') || {},
    forms: (state) => get(state.event, 'forms') || [],
    activeItemId: (state) => state.activeItemId,
    activeItem: (state) =>
      state.data.find((item) => item.id === state.activeItemId),
    title: (state) => state.title,
    logo: (state) => state.logo,
    bg: (state) => state.bg,
    event: (state) => state.event,
    scope: (state) => state.event && `event|${state.event.id}`,
    user: (state) => state.user,
    isAdmin: (state) => _.includes(_.get(state, 'user.tags'), 'admin'),
    isShopEnabled: (state) => _.includes(_.get(state, 'user.tags'), 'shop'),
    eventLikes: (state) => get(state.event, 'likes') || {},
    eventMeta: (state) => (state.event && state.event.meta) || {},
    userLikes: (state) => state.userLikes,
    userMeta: (state) => state.userMeta,
    likes: (state) => state.likes,
    chatItem: (state) =>
      find(get(state, 'event.items'), (item) => item.type === 'chat'),
    lastChatReadTimeStamp: (state) => state.lastChatReadTimeStamp,
    timezone: (state, timezone) => {
      moment.tz.setDefault(timezone);
    },
    eventId: (state) => _.get(state, 'event.id'),
    eventSlug: (state) => _.get(state, 'event.slug'),
  },
  mutations: {
    activeItemId: (state, payload) => (state.activeItemId = payload),
    event: (state, payload) => {
      state.event = payload;
      if (payload == null) {
        state.navItems = [];
        state.data = [];
        state.title = null;
        state.bg = null;
        state.logo = null;
        state.timezone = null;
        state.userLikes = {};
        state.userMeta = {};
        state.lastChatReadTimeStamp = null;
      } else {
        const { title, theme, id } = payload;
        state.title = title;
        state.bg = theme && theme.background;
        state.logo = null;
        state.userLikes = {};
        const items = get(payload, 'items') || [];
        const pages = get(payload, 'pages') || [];
        const itemsById = keyBy(items, '_id');
        state.navItems = pages.map((page) => {
          const { slug: id, title, visible } = page;
          return { id, title, visible };
        });
        state.lastChatReadTimeStamp = DefaultStorage.getItem(
          `event:${id}:lastChatReadTimeStamp`
        );
        state.timezone = get(payload, 'timezone', null) || null;
        moment.tz.setDefault(state.timezone);
        state.data = pages.map((page) => {
          const { slug: id, title, content, layout } = page;
          return {
            id,
            title,
            layout,
            content: (content || []).map((item) => {
              const { itemId } = item;
              const rootItem = itemsById[itemId];
              const result = {
                _id: item._id,
                ...omit(rootItem, ['params']),
                ...omit(item, ['_id', 'params']),
                params: {
                  ...get(rootItem, 'params'),
                  ...get(item, 'params'),
                },
              };
              return result;
            }),
          };
        });
      }
    },
    setLastChatReadTimeStamp: (state) => {
      const now = new Date();
      state.lastChatReadTimeStamp = now;
      DefaultStorage.setItem(
        `event:${state.event.id}:lastChatReadTimeStamp`,
        now
      );
    },
    likes: (state, likes) => {
      state.likes = likes;
    },
    user: (state, payload) => {
      state.user = payload;
      state.userLikes = state.user && state.user.likes ? state.user.likes : {};
      state.userMeta = state.user && state.user.meta ? state.user.meta : {};
    },
    setUserForm: (state, payload) => {
      const { id, data } = payload;
      state.user.forms = {
        ...state.user.forms,
        [id]: {
          data,
          submitDt: new Date(),
        },
      };
    },
    setLike: (state, payload) => {
      const { id, like } = payload;
      if (state.userLikes == null) {
        state.userLikes = {};
      }
      state.userLikes[id] = like;
      state.userLikes = {
        ...state.userLikes,
      };
    },
  },
  actions: {
    async getEvent({ commit }, eventIdOrSlug) {
      const event = await api.getEvent(eventIdOrSlug);
      commit('event', event);
      return event;
    },
    async getMyProfile({ commit, dispatch }) {
      const user = await api.getMyProfile();
      commit('user', user);
      dispatch('chat/getChatMessages');
      return user;
    },
    async updateMyProfile({ dispatch }, update) {
      try {
        await api.updateMyProfile(update);
        await dispatch('getMyProfile');
      } catch (err) {
        return err;
      }
    },
    async updateMyAvatar({ dispatch }, update) {
      try {
        await api.updateMyAvatar(update);
        await dispatch('getMyProfile');
      } catch (err) {
        console.error(err);
        return err;
      }
    },
    async authSignIn({ dispatch, getters: { scope } }, payload) {
      try {
        await api.authSignIn({ ...payload, scope });
        await dispatch('getMyProfile');
        return true;
      } catch (err) {
        return err;
      }
    },
    async getGratitudeSummary() {
      try {
        const result = await api.getGratitudeSummary();
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async getGratitudeStats() {
      try {
        const result = await api.getGratitudeStats();
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async getGratitudeUsers() {
      try {
        const result = await api.getGratitudeUsers();
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async getGratitudeMessages(context, payload) {
      try {
        const result = await api.getGratitudeMessages(payload || {});
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async postGratitudeMessage(context, payload) {
      try {
        const result = await api.postGratitudeMessage(payload);
        return result;
      } catch (err) {
        console.error(err);
      }
    },
    async getShopProducts() {
      return api.getShopProducts();
    },
    async getShopBalanceRecords() {
      return api.getShopBalanceRecords();
    },
    async getShopUsers() {
      return api.getShopUsers();
    },
    async getShopProductPurchases() {
      return api.getShopProductPurchases();
    },
    async postShopProductPurchase(context, { product, quantity }) {
      return api.postShopProductPurchase({ product, quantity });
    },
    async postShopBalanceTransfer(context, { to, amount }) {
      return api.postShopBalanceTransfer({ to, amount });
    },
    async authEmailSignIn({ getters: { scope } }, { email }) {
      try {
        const result = await api.authEmailSignIn({ email, scope });
        return result;
      } catch (err) {
        return err;
      }
    },
    async authSignUp({ dispatch, getters: { scope } }, payload) {
      try {
        await api.authSignUp({ ...payload, scope });
        await dispatch('getMyProfile');
        return true;
      } catch (err) {
        return err;
      }
    },
    authSignOut({ commit }) {
      commit('user', null);
      api.setToken(null);
    },
    runCommand({ getters: { scope } }, { itemId, command, params }) {
      return api.runCommand({ scope, itemId, command, params });
    },
    uploadFileToS3Storage(context, { file, uploadUrl, headers }) {
      return api.uploadFileToS3Storage(file, uploadUrl, headers);
    },
    async postLike(
      { getters: { event }, commit, dispatch },
      { id, like, exclusive = false }
    ) {
      commit('setLike', { id, like });
      const response = await api.postLike(id, like, event.id, exclusive);
      dispatch('getLikes');
      return response;
    },
    async postEventInvitation({ getters: { event } }, secret) {
      return api.postEventInvitation(event.id, secret);
    },
    async getLikes({ getters: { event }, commit }) {
      const { data: likes } = await api.getEventLikes(event.id);
      commit('likes', likes);
    },
  },
  modules: {
    quest,
    chat,
    challenge,
  },
};
