<template>
  <grid-month>
    <template v-for="week in weeks">
      <template v-for="day in week.days">
        <day-of-month
          :day="day.day"
          :key="`${week.date}-${day.date}`"
          :disabled="!(dates || []).includes(day.date)"
          :active="day.date === today"
          @click="onDateClick(day.date)"
          :force-background="day.date.includes('-12-')"
          :background-image="images && images[day.date]"
          :clickable="!disableFuture || !day.future"
          :file-name-template="day.monthNumber === 4 ? 'may_#day#.jpg' : null"
          :show-border="showBorder"
          :show-number="showNumber"
        />
      </template>
    </template>
  </grid-month>
</template>

<script>
import moment from "moment-timezone";
moment.locale("ru");
import _ from "lodash";
import GridMonth from "../../../layouts/advent-one/components/gridMonth";
import DayOfMonth from "../../../layouts/advent-one/components/dayOfMonth";

export default {
  name: "EventCalendar",
  props: [
    "min",
    "max",
    "dates",
    "images",
    "show-border",
    "show-number",
    "disable-future",
  ],
  components: {
    GridMonth,
    DayOfMonth,
  },
  computed: {
    today() {
      return moment().format("YYYY-MM-DD");
    },
    first() {
      return moment(this.min || _.first(this.sortedDates))
        .startOf("isoWeek")
        .toDate();
    },
    last() {
      return moment(this.max || _.last(this.sortedDates))
        .endOf("isoWeek")
        .startOf("day")
        .toDate();
    },
    days() {
      const numberOfDays = (this.last - this.first) / (24 * 60 * 60 * 1000) + 1;
      return _.times(numberOfDays, (index) => {
        return moment(this.first).add(index, "days").format("YYYY-MM-DD");
      });
    },
    weeks() {
      const now = new Date();
      return this.days.reduce((acc, d) => {
        const dayOfWeek = moment(d).isoWeekday();
        if (dayOfWeek === 1) {
          acc.push({ date: d, days: [] });
        }
        const m = moment(d);
        acc[acc.length - 1].days.push({
          date: d,
          count: 0,
          day: m.format("D"),
          weekDay: m.format("dddd"),
          month: m.format("MMMM"),
          monthNumber: m.month(),
          future: m.isAfter(now, "days"),
          past: m.isBefore(now, "days"),
        });
        return acc;
      }, []);
    },
    sortedDates() {
      return _.sortBy(
        _.map(this.dates, (d) => moment(d).toDate()),
        (d) => d.getTime()
      );
    },
  },
  methods: {
    onDateClick(date) {
      if ((this.dates || []).includes(date)) {
        this.$emit("select-date", date);
      }
    },
  },
};
</script>
