<template>
  <v-responsive
    :aspect-ratio="16 / 9"
    :class="{
      'pl-14': $vuetify.breakpoint.mobile,
      'pr-14': $vuetify.breakpoint.mobile,
    }"
  >
    <iframe
      v-if="isYouTube"
      width="100%"
      height="100%"
      border="0"
      style="border: none"
      allowfullscreen
      :src="`https://www.youtube.com/embed/${videoURI}${
        autoplay ? '?autoplay=1&mute=1' : ''
      }`"
    >
    </iframe>
    <video
      v-else
      controls
      :autoplay="autoplay"
      height="100%"
      width="100%"
      :src="videoURI"
    />
  </v-responsive>
</template>

<script>
const youtubeRegex = /(?:https?:\/\/(www\.)?youtube.*watch\?v=)([a-zA-Z0-9\-_]+)/i;

export default {
  name: "VideoPlayer",
  props: ["url", "autoplay"],
  computed: {
    isYouTube() {
      if (!this.url) return false;
      return youtubeRegex.test(this.url);
    },
    videoURI() {
      if (this.isYouTube) {
        const match = this.url.match(youtubeRegex);
        return match[2];
      }
      return this.url;
    },
  },
};
</script>