import { mapGetters, mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions({
      postLike: 'event/postLike',
      getEvent: 'event/get',
    }),
  },
  computed: {
    ...mapGetters({
      eventLikes: 'event/eventLikes',
      userLikes: 'event/userLikes',
    }),
    isLikedByUser: {
      get: function() {
        return this.userLikes[this.likeableObjectId] === true;
      },
      set: async function(newValue) {
        this.postLike({
          id: this.likeableObjectId,
          like: newValue,
          ...(this.exclusive ? { exclusive: true } : {}),
        });
      },
    },
  },
};
