import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import themeMixin from './eventTheme';

import _ from 'lodash';

const profileFieldsMapping = {
  name: {
    label: 'Имя и Фамилия',
    type: 'text',
  },
  email: {
    label: 'Эл. почта',
    type: 'email',
  },
  phone: {
    label: 'Телефон',
    type: 'phone',
  },
  region: {
    label: 'Регион',
    type: 'options',
    options: ['Москва', 'Санкт-Петербург', 'Омск', 'Нижний Новгород'],
  },
};

export default {
  mixins: [themeMixin],
  props: ['auth'],
  data() {
    return {
      dialog: false,
      dialogMode: 'signin', // signin|signup
      loading: false,
      username: null,
      code: null,
      password: null,
      confirmPassword: null,
      alert: null,
      profile: {},
      email: {
        dialog: false,
        value: '',
        alert: null,
        success: null,
      },
      gratitudeSummary: null,
      gratitudeDialog: false,
      gratitudeUsers: null,
      gratitudeMessages: null,
      gratitudeMessagesFilter: 'all',
      gratitudeNewMessageTo: [],
      gratitudeNewMessageText: null,
      gratitudeNewMessageCorporateValue: null,
      gratitudeLoading: false,
      gratitudeNewMessageDialog: false,
      gratitudeStats: null,
      isGratitudeSubmitting: false,
    };
  },
  computed: {
    ...mapState({
      drawer: 'drawer',
    }),
    ...mapGetters({
      eventId: 'event/eventId',
      eventSlug: 'event/eventSlug',
      title: 'event/title',
      logo: 'event/logo',
      event: 'event/event',
      user: 'event/user',
    }),
    isEventSupportPvp() {
      return this.$route.meta?.isEventSupportPvp === true;
    },
    editableProfile() {
      return _.get(this.event, 'editableProfile', false);
    },
    userId() {
      return this.user && this.user.id;
    },
    formIsValid() {
      return this.dialogMode === 'signin'
        ? this.password && this.username
        : (this.isAuthByCode ? !!this.code : true) &&
        this.username &&
        this.password &&
        this.password === this.confirmPassword &&
        !this.userProfileFields.find((f) => !this.profile[f.key]);
    },
    isAuthByCode() {
      return this.event && this.event.authByCode;
    },
    userProfileFields() {
      return ((this.event && this.event.userProfileFields) || [])
        .map((key) => {
          const f = profileFieldsMapping[key];
          return f == null
            ? null
            : {
              ...f,
              key,
            };
        })
        .filter((f) => f);
    },
    authModes() {
      const modes = _.get(this.event, 'auth.modes');
      return _.isArray(modes) ? modes : [];
    },
    authModeSecret() {
      return this.authModes.includes('secret');
    },
    authModeJwt() {
      return this.authModes.includes('jwt');
    },
    authModeIsEmail() {
      return this.authModes.includes('email');
    },
    authModeSignIn() {
      return this.authModes.includes('signin');
    },
    authModeSignUp() {
      return this.authModes.includes('signup');
    },
    gratitudeIsEnabled() {
      return _.get(this.event, 'gratitude.enabled') || false;
    },
    gratitudeIsExclusive() {
      return _.get(this.event, 'gratitude.exclusive') || false;
    },
    gratitudeUsersByIDs() {
      return _.keyBy(this.gratitudeUsers, '_id');
    },
    recentGratitudeDisplayMessages() {
      return _.slice(this.gratitudeDisplayMessages, 0, 200);
    },
    gratitudeDisplayMessages() {
      let filteredItems = this.gratitudeMessages;
      if (this.gratitudeMessagesFilter === 'from') {
        filteredItems = _.filter(
          this.gratitudeMessages,
          (item) => item.from === this.userId
        );
      } else if (this.gratitudeMessagesFilter === 'to') {
        filteredItems = _.filter(this.gratitudeMessages, (item) =>
          (item.to || []).includes(this.userId)
        );
      }
      return _.map(filteredItems, (item) => {
        return {
          ...item,
          to: _.map(item.to, (userId) => this.gratitudeUsersByIDs[userId]),
          from: this.gratitudeUsersByIDs[item.from],
        };
      });
    },
    gratitudeUsersButNotMe() {
      return _.filter(this.gratitudeUsers, (item) => item._id !== this.userId);
    },
    gratitudeAvailableUsers() {
      const users = _.filter(
        this.gratitudeUsersButNotMe,
        (item) => !this.gratitudeUsedUsers.includes(item._id)
      );
      return _.map(users, (item) => ({
        value: item._id,
        label: _.get(item, 'profile.name') || _.get(item, 'profile.email'),
      }));
    },
    gratitudeMyMessages() {
      return _.filter(
        this.gratitudeMessages,
        (item) => item.from === this.userId
      );
    },
    gratitudeUsedCorpValues() {
      const set = _.reduce(
        this.gratitudeMyMessages,
        (acc, item) => {
          const corpValue = _.get(item, `meta.corporate-value`);
          if (corpValue) {
            acc.add(corpValue);
          }
          return acc;
        },
        new Set()
      );
      return Array.from(set);
    },
    gratitudeAvailableCorpValues() {
      return _.filter(
        this.gratitudeCorpValues,
        (item) => !this.gratitudeUsedCorpValues.includes(item)
      );
    },
    gratitudeUsedUsers() {
      const set = _.reduce(
        this.gratitudeMyMessages,
        (acc, item) => {
          _.forEach(_.get(item, 'to'), (item) => {
            acc.add(item);
          });
          return acc;
        },
        new Set()
      );
      return Array.from(set);
    },
    isGratitudeFormValid() {
      const minTextLength = 100;
      const maxTextLength = 1000;
      const isTextOk =
        this.gratitudeNewMessageText &&
        this.gratitudeNewMessageText.length >= minTextLength &&
        this.gratitudeNewMessageText.length <= maxTextLength;
      const isToOk =
        _.isArray(this.gratitudeNewMessageTo) &&
        this.gratitudeNewMessageTo.length <= 10 &&
        this.gratitudeSummary &&
        this.gratitudeSummary.sent + this.gratitudeNewMessageTo.length <= 100;
      return (
        !_.isEmpty(this.gratitudeNewMessageTo) &&
        isToOk &&
        this.gratitudeNewMessageCorporateValue &&
        isTextOk
      );
    },
    gratitudeCorpValues() {
      return _.get(this.event, 'meta.corporate-values') || [];
    },
    gratitudeBackground() {
      return _.get(this.event, 'theme.background');
    },
  },
  async created() {
    if (this.gratitudeIsEnabled) {
      this.fetchGratitudeSummary();
    }
  },
  watch: {
    gratitudeIsEnabled(val) {
      if (val) {
        this.fetchGratitudeSummary();
      }
    },
    gratitudeDialog(val) {
      if (val) {
        this.fetchGratitudeStats();
        this.fetchGratitudeUsers();
        this.fetchGratitudeMessages();
      }
      this.fetchGratitudeSummary();
    },
  },
  methods: {
    ...mapMutations({
      setDrawer: 'drawer',
    }),
    ...mapActions({
      authSignIn: 'event/authSignIn',
      authSignUp: 'event/authSignUp',
      authSignOut: 'event/authSignOut',
      authEmailSignIn: 'event/authEmailSignIn',
      getGratitudeSummary: 'event/getGratitudeSummary',
      getGratitudeStats: 'event/getGratitudeStats',
      getGratitudeUsers: 'event/getGratitudeUsers',
      getGratitudeMessages: 'event/getGratitudeMessages',
      postGratitudeMessage: 'event/postGratitudeMessage',
    }),
    async fetchGratitudeSummary() {
      try {
        const { data } = await this.getGratitudeSummary();
        this.gratitudeSummary = data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchGratitudeStats() {
      try {
        const { data } = await this.getGratitudeStats();
        this.gratitudeStats = data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchGratitudeMessages() {
      try {
        this.gratitudeLoading = true;
        const { data } = await this.getGratitudeMessages();
        this.gratitudeMessages = data;
      } catch (err) {
        console.error(err);
      } finally {
        setTimeout(() => {
          this.gratitudeLoading = false;
        }, 5000);
      }
    },
    async fetchGratitudeUsers() {
      try {
        const { data } = await this.getGratitudeUsers();
        this.gratitudeUsers = data;
      } catch (err) {
        console.error(err);
      }
    },
    async onGratitudeSubmit() {
      if (this.isGratitudeSubmitting) {
        return;
      }
      try {
        this.isGratitudeSubmitting = true;
        const text = this.gratitudeNewMessageText;
        const value = this.gratitudeNewMessageCorporateValue;
        const to = this.gratitudeNewMessageTo;
        const payload = {
          text,
          to,
          meta: { 'corporate-value': value },
        };
        const { data } = await this.postGratitudeMessage(payload);
        this.gratitudeMessages = [data, ...this.gratitudeMessages];
        // clear form and hide dialog
        this.gratitudeNewMessageDialog = false;
        this.gratitudeNewMessageText = '';
        this.gratitudeNewMessageTo = [];
        this.gratitudeNewMessageCorporateValue = '';
      } catch (err) {
        console.error(err);
      }
      this.isGratitudeSubmitting = false;
      this.fetchGratitudeSummary();
    },
    resetForm() {
      this.profile = {};
      this.password = '';
      this.confirmPassword = '';
      this.code = null;
    },
    onSignIn() {
      if (this.authModeIsEmail) {
        this.email.alert = null;
        this.email.success = null;
        this.email.dialog = true;
      } else if (this.authModeSignIn) {
        this.resetForm();
        this.dialogMode = 'signin';
        this.dialog = true;
      }
    },
    onSignUp() {
      this.resetForm();
      this.dialogMode = 'signup';
      this.dialog = true;
    },
    onSignOut() {
      this.authSignOut();
    },
    async onAuthFormSubmit() {
      const {
        username,
        code,
        password,
        dialogMode,
        isAuthByCode,
        profile,
      } = this;
      const data = {
        username,
        password,
        ...(isAuthByCode && dialogMode === 'signup' ? { code } : {}),
        ...(dialogMode === 'signup' ? { profile } : {}),
      };

      this.loading = true;
      let result = null;
      if (dialogMode === 'signin') {
        result = await this.authSignIn(data);
      } else {
        result = await this.authSignUp(data);
      }

      this.loading = false;
      if (result === true) {
        this.alert = null;
        this.dialog = false;
      } else {
        this.alert = result.message;
      }
    },
    async onAuthEmailSubmit() {
      const data = {
        email: this.email.value,
      };
      this.loading = true;
      this.email.success = null;
      this.email.alert = null;
      try {
        const result = await this.authEmailSignIn(data);
        if (result && result.ok === true) {
          this.email.success = result.message;
        } else {
          this.email.alert = result.message;
        }
      } catch (err) {
        this.email.alert = err && err.message;
      }
      this.loading = false;
    },
    toggleDrawer() {
      this.setDrawer(!this.drawer);
    },
  },
};
