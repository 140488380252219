<template>
  <div>
    <v-row>
      <v-col
        :cols="12"
        v-for="(item, index) in items"
        xl="6"
        lg="6"
        md="6"
        sm="6"
        xs="12"
        :key="`gallery-item-${item.id}`"
      >
        <gallery-item
          :key="item.id"
          :url="item.url"
          :title="item.title"
          :aspect-ratio="item.aspectRatio"
          :mode="item.mode"
          :description="item.description"
          @click="onItemClick(index)"
        />
      </v-col>
    </v-row>
    <v-dialog fullscreen :value="isModalOpen">
      <div style="width: 100%; height: 100vh; position: relative">
        <div
          style="
            top: 10px;
            right: 10px;
            position: absolute;
            color: white;
            z-index: 100;
          "
        >
          <v-btn icon color="white">
            <v-icon @click="isModalOpen = false">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-carousel
          :cycle="false"
          style="width: 100%; background-color: #000d"
          height="100vh"
          :show-arrows="true"
          :continuous="true"
          v-model="selectedItemIndex"
        >
          <v-carousel-item
            v-for="(item, index) of items"
            style="height: 100%; width: 100%"
            :key="index"
          >
            <v-img
              :src="item.url"
              style="width: 100%; height: 100%"
              :contain="true"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";
import GalleryItem from "./GalleryItem";

export default {
  name: "ImageGallery",
  props: ["params"],
  components: {
    GalleryItem,
  },
  data() {
    return {
      isModalOpen: false,
      selectedItemIndex: 0,
    };
  },
  created() {
    console.log(this.$vuetify.breakpoint);
  },
  computed: {
    ...mapGetters({
      event: "event/event",
      likes: "event/likes",
    }),
    galleryName() {
      return this.params.name;
    },
    galleryShowAuthor() {
      return !!this.params.showAuthor;
    },
    items() {
      return _.map(_.get(this.params, `images`) || [], (item) => {
        const params = _.get(item, "params");
        const { _id: id } = item;
        const {
          image: url,
          title,
          description,
          "image-aspect-ratio": aspectRatio,
          "image-mode": mode = "contain",
        } = params || {};
        return {
          id,
          url,
          title,
          description,
          aspectRatio,
          mode,
        };
      });
    },
    isLikeable() {
      return this.params.likeable === true;
    },
    isShowTotal() {
      return this.params.showTotal === true;
    },
  },
  methods: {
    onItemClick(index) {
      const item = this.items[index];
      console.log("click", item);
      this.selectedItemIndex = index;
      this.isModalOpen = true;
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
</style>