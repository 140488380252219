class Storage {
  constructor(prefix = null) {
    this.prefix = prefix;
  }
  getItem(key) {
    try {
      const fullKey = `${this.prefix || ''}${key}`;
      const strValue = localStorage.getItem(fullKey);
      return JSON.parse(strValue);
    } catch (err) {
      return null;
    }
  }
  setItem(key, value) {
    try {
      const fullKey = `${this.prefix || ''}${key}`;
      if (value != null) {
        localStorage.setItem(fullKey, JSON.stringify(value));
      } else {
        localStorage.removeItem(fullKey);
      }
      return true;
    } catch (err) {
      return false;
    }
  }
}

export default new Storage('yo-events:');
