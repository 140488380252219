import Vue from 'vue';
import VueMeta from 'vue-meta';
import VueToast from 'vue-toast-notification';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';

import 'vue-turnjs/dist/vue-turnjs.esm.css';
Vue.config.productionTip = false;

// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-default.css';

Vue.use(VueMeta);

Vue.use(VueToast);

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount('#app');
