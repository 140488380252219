<template>
  <v-app-bar color="#12275C" app dark>
    <template v-if="$vuetify.breakpoint.mobile">
      <v-btn
        dark
        color="transparent"
        depressed
        @click="toggleDrawer()"
        style="text-shadow: 1px 1px 2px black"
      >
        <v-icon dark> mdi-menu </v-icon>
      </v-btn>
    </template>
    <template v-if="$vuetify.breakpoint.mdAndUp">
      <v-spacer></v-spacer>
      <v-scale-transition>
        <div class="d-flex align-center">
          <v-img
            :alt="title"
            class="shrink mr-2"
            contain
            :src="logo"
            width="40"
          />
          <slot name="title">
            <h2
              style="font-weight: 400; text-shadow: 1px 1px 2px black"
              transition="scale-transition"
              class="ml-1"
            >
              {{ title }}
            </h2>
          </slot>
        </div>
      </v-scale-transition>
    </template>
    <v-spacer></v-spacer>
    <slot name="nav-right"></slot>
    <v-btn
      class="primary darken-2 mr-4"
      style="text-shadow: 1px 1px 2px black"
      v-if="alarmRemaining"
      >{{ alarmRemaining }}</v-btn
    >
    <v-btn
      class="primary darken-2 mr-4"
      style="text-shadow: 1px 1px 2px black"
      v-if="gratitudeSummary"
      @click="gratitudeDialog = true"
    >
      <img
        :src="require('../pages/otr-2022/assets/mandarin.png')"
        :height="24"
        :width="24"
      />
      <span v-if="gratitudeSummary.sent" class="ml-2"
        >Отправлено: {{ gratitudeSummary.sent }}</span
      >
      <span v-if="gratitudeSummary.received" class="ml-2"
        >Получено: {{ gratitudeSummary.received }}</span
      >
    </v-btn>
    <user-menu
      :user="user"
      @signin="onSignIn"
      @signup="onSignUp"
      @signout="onSignOut"
      :editableProfile="editableProfile"
      @edit-profile="onEditProfile"
      :signin="authModeSignIn || authModeIsEmail"
      :signup="authModeSignUp"
    />

    <v-btn
      class="ml-2"
      @click="onOpenChallenge"
      v-if="
        activeChallenge != null &&
        (activeChallenge.status === 'ready' ||
          activeChallenge.status === 'accepted')
      "
      elevation="2"
      color="primary"
    >
      <span class="pr-2" v-if="activeChallengeRemaining">{{
        activeChallengeRemaining
      }}</span
      ><v-icon>mdi-dice-5</v-icon></v-btn
    >

    <v-dialog
      :value="selectedChallenge != null"
      :max-width="1200"
      :min-width="600"
      scrollable
      width="unset"
      @input="onChangeSelectedChallengeDialog"
    >
      <v-card :min-width="600">
        <challenge />
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="auth !== 'links-only'"
      v-model="dialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 400"
    >
      <v-card :loading="loading">
        <v-card-title class="headline primary--text">
          {{ dialogMode === "signin" ? "Вход" : "Регистрация" }}
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="code"
            v-if="isAuthByCode && dialogMode === 'signup'"
            label="Код"
            persistent-hint
            hint="Введите код, присланный организатором"
          />
          <v-text-field v-model="username" label="Имя и фамилия" />
          <template
            v-if="dialogMode === 'signup' && userProfileFields.length > 0"
          >
            <template v-for="f in userProfileFields">
              <v-select
                v-if="f.type === 'options'"
                :key="`select-${f.key}`"
                :items="f.options"
                v-model="profile[f.key]"
                :label="f.label"
              />
              <v-text-field
                v-else
                :key="`text-${f.key}`"
                v-model="profile[f.key]"
                :type="f.type"
                :label="f.label"
              />
            </template>
          </template>
          <v-text-field v-model="password" label="Пароль" type="password" />
          <v-text-field
            v-model="confirmPassword"
            v-if="dialogMode === 'signup'"
            type="password"
            label="Подтвердите пароль"
          />
          <v-alert text type="warning" v-if="email.alert">
            {{ email.alert }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="dialog = false"> Отмена </v-btn>
          <v-btn
            color="primary"
            text
            @click="onAuthFormSubmit"
            :disabled="!formIsValid"
          >
            {{ dialogMode === "signin" ? "Войти" : "Регистрация" }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="gratitudeIsEnabled" v-model="gratitudeDialog" fullscreen>
      <v-sheet
        class="d-flex justify-start flex-column"
        style="
          background-color: transparent;
          height: 100vh;
          width: 100%;
          overflow-y: scroll;
          background-position: center;
          background-size: cover;
          background-attachment: fixed;
        "
        :style="{
          backgroundImage: `url(${gratitudeBackground})`,
        }"
      >
        <div class="d-flex">
          <v-app-bar class="align-center">
            <v-spacer></v-spacer>
            <v-btn
              class="my-4"
              @click="gratitudeNewMessageDialog = true"
              color="primary"
              >Подарить мандаринку</v-btn
            >
            <v-btn @click="gratitudeDialog = false" text
              ><v-icon>mdi-close</v-icon></v-btn
            >
          </v-app-bar>
        </div>

        <div
          style="overflow-y: scroll; flex: 1"
          class="d-flex flex-column flex-md-row"
        >
          <v-sheet
            class="ma-4 pa-4 gratitude-left-menu"
            style="background-color: #fff9; width: 720px"
            round
          >
            <v-card v-if="gratitudeStats">
              <v-card-title>
                Всего отправлено мандаринок
                <v-spacer></v-spacer>
                {{ gratitudeStats.total }}
              </v-card-title>
            </v-card>
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-if="gratitudeStats && gratitudeStats.topFrom.length > 0"
              >
                <v-expansion-panel-header
                  >Топ 10 дарителей</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-list-item
                    two-line
                    v-for="item in gratitudeStats.topFrom"
                    :key="`gratitude-top-from-${item._id}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ item.dept }},
                        {{ item.region }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      {{ item.count }}
                    </v-list-item-action>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel
                v-if="gratitudeStats && gratitudeStats.topTo.length > 0"
              >
                <v-expansion-panel-header
                  >Топ 10 получателей</v-expansion-panel-header
                >
                <v-expansion-panel-content>
                  <v-list-item
                    two-line
                    v-for="item in gratitudeStats.topTo"
                    :key="`gratitude-top-from-${item._id}`"
                  >
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                      <v-list-item-subtitle
                        >{{ item.dept }},
                        {{ item.region }}</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action>
                      {{ item.count }}
                    </v-list-item-action>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header>Правила</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card>
                    <img
                      :src="
                        require('../pages/otr-2022/assets/otr-mandarin.png')
                      "
                      style="width: 50%"
                      class="mt-4"
                    />
                    <v-card-text class="text-left">
                      <p class="my-2">
                        Ура! Подвезли мандаринки —ммммм, как запахло Новым
                        годом. В этот раз мандарин стал символом и «валютой»
                        акции «Скажи спасибо». Так что срочно набирайте их
                        побольше (но не больше 100 штук в одни руки 😉) и щедро
                        раздавайте коллегам вместе с тёплыми словами
                        благодарности.
                      </p>

                      <p class="my-2"><strong>📌 Немного правил:</strong></p>

                      <ol class="my-2">
                        <li>
                          Благодарность одному человеку можно отправить только
                          один раз.
                        </li>
                        <li>
                          Мандаринку надо сопроводить содержательным текстом от
                          100 до 1000 символов. Внимательно подумайте над тем,
                          что вы напишите своему коллеге, ведь ваше сообщение
                          увидят все пользователи платформы, а уведомление с
                          текстом благодарности улетит прямиком в почту
                          получателя.
                        </li>
                        <li>
                          Перед отправкой надо выбрать корпоративную ценность,
                          за соответствие которой вы благодарите получателя.
                        </li>
                        <li>
                          Одновременно можно поблагодарить несколько коллег, но
                          при этом вы потратите столько мандаринок, сколько
                          получателей у благодарности.
                        </li>
                        <li>Максимально можно отправить 100 благодарностей.</li>
                      </ol>

                      <p class="my-2">
                        <strong>🍊Скажи спасибо — угости мандаринкой!</strong>
                      </p>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-sheet>
          <v-sheet
            class="d-flex flex-column justify-start ma-4 pa-4"
            style="flex: 1; overflow-y: scroll; background-color: #fffa"
            round
          >
            <v-radio-group
              v-model="gratitudeMessagesFilter"
              v-if="
                gratitudeSummary &&
                (gratitudeSummary.sent || gratitudeSummary.received)
              "
              row
              class="align-center"
            >
              <v-radio label="Все мандаринки" value="all"></v-radio>
              <v-radio
                label="Я отправил"
                v-if="gratitudeSummary && gratitudeSummary.sent"
                value="from"
              ></v-radio>
              <v-radio
                label="Мне отправили"
                v-if="gratitudeSummary && gratitudeSummary.received"
                value="to"
              ></v-radio>
            </v-radio-group>
            <template v-if="gratitudeLoading">
              <div class="d-flex align-center">
                <v-progress-circular
                  indeterminate
                  small
                  color="primary"
                ></v-progress-circular>

                <div class="ml-2">Загрузка ...</div>
              </div>
            </template>
            <v-timeline align-top dense v-if="!gratitudeLoading">
              <v-fade-transition :group="true" :hideOnLeave="true">
                <v-timeline-item
                  v-for="item in recentGratitudeDisplayMessages"
                  :key="`gratitude-message-${item._id}`"
                  large
                >
                  <template v-slot:icon>
                    <v-avatar>
                      <img
                        :src="require('../pages/otr-2022/assets/mandarin.png')"
                      />
                    </v-avatar>
                  </template>

                  <v-card class="elevation-2">
                    <v-card-text>
                      <div class="mb-2">
                        <strong>{{
                          item.from &&
                          item.from.profile &&
                          item.from.profile.name
                        }}</strong>
                        благодарит за проявление ценности
                        <strong>{{
                          item && item.meta && item.meta["corporate-value"]
                        }}</strong>
                      </div>
                      <div class="mb-2">
                        <v-chip
                          v-for="toItem in item.to"
                          :key="`gratitude-to-${item._id}-${toItem._id}`"
                          label
                          color="primary"
                          class="mr-2 mb-2"
                          small
                          >{{
                            toItem && toItem.profile && toItem.profile.name
                          }}</v-chip
                        >
                      </div>
                      <div
                        class="mt-2"
                        v-html="
                          item.text
                            .replace(/\n/gi, '<br/>')
                            .replace(/</gi, '&lt;')
                            .replace(/>/gi, '&gt;')
                        "
                      ></div>
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-fade-transition>
            </v-timeline>
          </v-sheet>
        </div>
      </v-sheet>
    </v-dialog>
    <v-dialog
      v-model="gratitudeNewMessageDialog"
      :max-width="500"
      style="z-index: 1000"
    >
      <v-card>
        <v-card-title> Отправить мандаринку </v-card-title>
        <v-card-text>
          <v-autocomplete
            chips
            clearable
            deletable-chips
            multiple
            solo
            full-width
            item-text="label"
            item-value="value"
            label="Получатели благодарности"
            v-model="gratitudeNewMessageTo"
            :items="gratitudeAvailableUsers"
            hint="До 10 получателей"
            persistent-hint
          ></v-autocomplete>
          <v-select
            solo
            full-width
            label="Корпоративная ценность"
            v-model="gratitudeNewMessageCorporateValue"
            :items="gratitudeCorpValues"
          />
          <v-textarea
            label="Текст благодарности"
            multiple
            solo
            full-width
            auto-grow
            clearable
            counter
            hint="Минимум 100 символов, максимум - 1000"
            persistent-hint
            persistent-placeholder
            v-model.trim="gratitudeNewMessageText"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="gratitudeNewMessageDialog = false"
            >Отмена</v-btn
          >
          <v-btn
            color="primary"
            text
            :disabled="!isGratitudeFormValid || isGratitudeSubmitting"
            :loading="isGratitudeSubmitting"
            @click="onGratitudeSubmit"
            >Отправить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      :value="email.dialog && authModeIsEmail"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 400"
    >
      <v-card :loading="loading">
        <v-card-title class="headline primary--text"> Вход </v-card-title>
        <v-card-text>
          <v-text-field
            v-model.trim="email.value"
            persistent-hint
            hint="Введите свою эл. почту"
          />
          <v-alert text type="warning" v-if="email.alert">
            {{ email.alert }}
          </v-alert>
          <v-alert text type="success" v-if="email.success">
            {{ email.success }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="email.dialog = false">
            Отмена
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="!email.value"
            @click="onAuthEmailSubmit"
          >
            Получить ссылку для входа
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editProfileDialog"
      :fullscreen="$vuetify.breakpoint.mobile"
      :max-width="$vuetify.breakpoint.mobile ? null : 400"
    >
      <v-card>
        <v-img
          v-if="userProfile && userProfile.image"
          height="250"
          :src="userProfile.image"
        ></v-img>
        <v-card-title class="headline primary--text">
          Редактирование профиля
        </v-card-title>
        <v-card-text>
          <v-file-input
            :rules="editProfileDialogRules"
            v-model="editProfileForm.avatar"
            accept="image/png, image/jpeg"
            append-icon="mdi-camera"
            show-size
            label="Аватар"
          ></v-file-input>
          <v-text-field
            label="Фамилия и имя"
            v-model.trim="editProfileForm.name"
            persistent-hint
            hint="Ваши фамилия и имя"
          />
          <v-text-field
            label="Город рождения"
            v-model.trim="editProfileForm.birthCity"
            persistent-hint
          />
          <v-textarea
            label="О себе"
            v-model="editProfileForm.about"
            persistent-hint
            hint="Расскажите другим о себе"
          ></v-textarea>
          <v-textarea
            label="Интересный факт из жизни"
            v-model="editProfileForm.interestingFact"
            persistent-hint
          ></v-textarea>
          <v-alert text type="warning" v-if="email.alert">
            {{ email.alert }}
          </v-alert>
          <v-alert text type="success" v-if="email.success">
            {{ email.success }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="editProfileDialog = false">
            Отмена
          </v-btn>
          <v-btn color="primary" text :disabled="false" @click="onSaveProfile">
            Сохранить
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";
import eventNavBarMixin from "@/mixins/eventNavBarMixin";
import themeMixin from "@/mixins/eventTheme";
import UserMenu from "./UserMenu";
import Challenge from "../components/games/challenge/Index.vue";

import _ from "lodash";

export default {
  name: "EventNavBar",
  props: ["alarm-remaining"],
  mixins: [eventNavBarMixin, themeMixin],
  components: {
    UserMenu,
    Challenge,
  },
  data() {
    return {
      editProfileDialog: false,
      challengeDialog: false,
      editProfileDialogRules: [
        (value) =>
          !value ||
          value.size < 10000000 ||
          "Avatar size should be less than 10 MB!",
      ],
      editProfileForm: {
        name: null,
        about: null,
        pvp: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      selectedChallenge: "event/challenge/selectedChallenge",
      activeChallengeRemaining: "event/challenge/activeChallengeRemaining",
      activeChallenge: "event/challenge/activeChallenge",
    }),
    userProfile() {
      return _.get(this.user, "profile");
    },
  },
  methods: {
    ...mapMutations({
      setSelectedChallenge: "event/challenge/setSelectedChallenge",
    }),
    ...mapActions({
      updateMyProfile: "event/updateMyProfile",
      updateMyAvatar: "event/updateMyAvatar",
    }),
    onEditProfile() {
      console.log(this.user, this.userProfile);
      this.editProfileForm = {
        name: _.get(this.userProfile, "name", ""),
        about: _.get(this.userProfile, "about", ""),
        interestingFact: _.get(this.userProfile, "interestingFact", ""),
        birthCity: _.get(this.userProfile, "birthCity", ""),
        pvp: _.get(this.userProfile, "pvp", true),
        avatar: null,
      };
      this.editProfileDialog = true;
    },
    async onSaveProfile() {
      await this.updateMyProfile(this.editProfileForm);
      if (this.editProfileForm.avatar != null) {
        console.log("avatar", this.editProfileForm.avatar);
        await this.updateMyAvatar(this.editProfileForm.avatar);
      } else {
        console.log("avatar is empty");
      }
      this.editProfileDialog = false;
      Vue.$toast.open({
        type: "info",
        message: "Профиль успешно изменен",
        position: "top",
        duration: 5000,
        dismissible: true,
      });
    },
    onOpenChallenge() {
      this.setSelectedChallenge(this.activeChallenge);
    },
    onChangeSelectedChallengeDialog(val) {
      if (!val) {
        this.setSelectedChallenge(null);
      }
    },
  },
};
</script>

<style scoped>
.gratitude-left-menu {
  max-width: 500px;
}
@media only screen and (max-width: 1264px) {
  .gratitude-left-menu {
    display: none;
  }
}
@media only screen and (max-width: 1500px) {
  .gratitude-left-menu {
    width: 300px;
  }
}
</style>
