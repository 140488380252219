<template>
  <div>
    <h1>Sea Battle</h1>
    <game />
  </div>
</template>

<script>
import Game from "./Game.vue";

export default {
  name: "SeaBattle",
  components: {
    Game,
  },
};
</script>
<style lang="scss">
$size: 35;

.b-container {
  max-width: 880px;
  margin: 0 auto;
}
.b-canvas-wrapper {
  //background-color: #d0d0d0;
  width: 400px;
  height: 400px;
  //margin: 0 auto;
  margin-bottom: 2em;

  > svg {
    overflow: visible !important;
  }
}

.b-row {
  display: flex;
  margin: 0 -15px;
}

.b-col {
  float: left;
  padding: 0 15px;

  &-left {
    width: 50%;
  }
  &-right {
    width: 50%;
  }
}
.b-logo {
  margin-bottom: 2em;
  text-align: center;
}

.b-svg-ship {
  stroke: #00f;
  fill: rgba(0, 0, 255, 0.05);
  rx: 0;
  ry: 0;
  stroke-width: 2px;
  cursor: pointer;
  shape-rendering: crispEdges;

  &--moveble {
    //opacity: 0.2;

    stroke: #05e500;
    fill: rgba(30, 255, 0, 0.1);
  }
}

.b-svg-grid {
  &-item {
    fill: #fff;
    stroke: #b4b4ff;
    stroke-width: 1px;
    shape-rendering: crispEdges;

    &--rival {
      cursor: pointer;
      &:hover {
        fill: rgba(30, 255, 0, 0.24);
        //stroke-width: 2px;
        //stroke: #05e500;
        //fill: rgba(30, 255, 0, 0.1);
      }
    }
    &--miss {
      fill: #e9ebef;
    }
  }
}

.b-grid {
  position: relative;

  &-table {
    margin: 0;
    border-collapse: collapse;
    cursor: default;
    display: block;
    //position: relative;
    width: 400px;
    height: 400px;

    position: absolute;
    left: 29px;
    top: 29px;
  }

  &-item {
    border: 1px solid #b4b4ff;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: absolute;

    &-content {
      position: relative;
      height: 35px;
      width: 35px;
      font-size: 12px;
    }

    &--empty:hover & {
      &-content {
        &:after {
          border: 2px solid #40bf44;
          background: rgba(64, 191, 68, 0.05);
          position: absolute;

          width: #{$size}px;
          height: #{$size}px;

          top: 0;
          left: 0;
          margin: -2px;
          content: "";
          display: block;
          cursor: pointer;
          z-index: 2;

          box-sizing: content-box;
        }
      }
    }

    &--miss & {
      &-content {
        background-color: #f2f4f8;
      }
      &-status {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 4px;
        width: 4px;
        background: #333;
        border-radius: 50%;
        margin-top: -2px;
        margin-left: -2px;
      }
    }
    &--miss-auto & {
      &-status {
        background: silver;
      }
    }

    &--hit & {
      &-content {
        background: 0 0;
        position: relative;
      }

      &-status {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
        box-sizing: border-box;
        background: none;
        margin-left: 0;
        margin-top: 0;
        border-radius: 0;

        &:before,
        &:after {
          content: "";
          position: absolute;
          z-index: -1;
          background: red;
          -webkit-transform: rotate(-45deg);
          -moz-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
        }
        &:before {
          left: 50%;
          width: 1px;
          top: -25%;
          height: 150%;
          margin-top: 1px;
        }
        &:after {
          top: 50%;
          height: 1px;
          left: -25%;
          width: 150%;
          margin-left: -1px;
        }
      }
    }

    &--done & {
      &-content {
        &:after {
          content: "";
          display: block;
          height: #{$size + 2}px;
          width: #{$size + 2}px;
          border: 1px solid red;
          margin: -1px;
          position: absolute;
          z-index: 3;
          top: 0;
          left: 0;
        }
      }
    }

    &--x {
      @for $i from 0 through 9 {
        &-#{$i} {
          left: #{$i * ($size + 1)}px;
        }
      }
    }
    &--y {
      @for $i from 0 through 9 {
        &-#{$i} {
          top: #{$i * ($size + 1)}px;
        }
      }
    }
  }
}

.b-ship {
  z-index: 2;
  left: 0;
  top: 0;
  position: absolute !important;
  margin: -2px;

  border: 2px solid;

  width: #{1 * ($size)}px;
  height: #{1 * ($size)}px;

  box-sizing: content-box;

  border-color: #00f;
  background: rgba(0, 0, 255, 0.05);

  &--done {
    border-color: red;
    background: rgba(255, 0, 0, 0.05);
  }

  &--pos {
    @for $i from 0 through 4 {
      &-v-#{$i} {
        width: #{1 * ($size)}px;
        height: #{$i * ($size + 1)-1}px;
      }
      &-h-#{$i} {
        width: #{$i * ($size + 1)-1}px;
        height: #{1 * ($size)}px;
      }
    }
  }
}
</style>