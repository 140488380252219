import _ from 'lodash';
import api from '@/api';
import DefaultStorage from '@/utils/storage';

const initialMessages = [
  {
    id: new Date().getTime(),
    author: {
      id: 'support',
      name: 'Техническая поддержка',
    },
    data: {
      text: 'Здравствуйте. Чем могу Вам помочь?',
    },
    type: 'text',
    room: 'tech-support',
  },
];

export default {
  namespaced: true,
  state: {
    rooms: [
      {
        id: 'tech-support',
        title: 'Техническая поддержка',
      },
    ],
    bannedUsers: _.map(DefaultStorage.getItem('user-bans'), (item) => item),
    messages: [...initialMessages],
    selectedRoomIndex: 0,
  },
  getters: {
    participants: (state) => state.participants,
    messages: (state) => state.messages,
    rooms: (state, getters, rootState, rootGetters) => {
      const rooms = _.filter(
        _.get(rootGetters['event/chatItem'], 'params.rooms') || [],
        (room) => room.id
      );
      return [...rooms, ...state.rooms];
    },
    recentMesageTimeStamp: (state) => {
      const lastMessage = _.last(state.messages);
      return lastMessage && lastMessage.createDt;
    },
    selectedRoomIndex: (state) => state.selectedRoomIndex,
    bannedUsers: (state) => state.bannedUsers,
  },
  mutations: {
    appendTechSupportMessage: (state, payload) => {
      const { message } = payload;
      state.messages = [
        ...state.messages,
        {
          id: new Date().getTime(),
          author: {
            id: 'support',
            name: 'Техническая поддержка',
          },
          data: {
            text: message,
          },
          type: 'text',
          room: 'tech-support',
          createDt: new Date(),
        },
      ];
    },
    appendMyMessage: (state, payload) => {
      state.messages = [...state.messages, payload];
    },
    appendMessage: (state, message) => {
      state.messages = [...state.messages, message];
    },
    resetChat: (state) => {
      state.messages = [...initialMessages];
    },
    setSelectedRoomIndex: (state, index) => {
      state.selectedRoomIndex = index;
    },
    setMessages: (state, messages) => {
      state.messages = messages;
    },
    banUser: (state, userId) => {
      state.bannedUsers = [...state.bannedUsers, userId];
      DefaultStorage.setItem('user-bans', state.bannedUsers);
    },
  },
  actions: {
    appendChatRoomMessage(context, payload) {
      const event = context.rootGetters['event/event'];
      if (event == null) return null;
      const user = context.rootGetters['event/user'];
      if (event.id == payload.eventId) {
        const {
          userId,
          chatId: room,
          message: text,
          userName,
          userDisplayName,
        } = payload;
        const message = {
          id: new Date().getTime(),
          author: {
            id: userId,
            name: userDisplayName || userName,
            me: (user && user.id) === userId,
          },
          data: {
            text,
          },
          type: 'text',
          room,
          createDt: new Date(),
        };
        context.commit('appendMessage', message);
      }
    },
    async getChatMessages({ rootGetters, commit }) {
      try {
        const eventId = _.get(rootGetters['event/event'], 'id');
        const { data } = await api.getEventChatMessages(eventId);
        commit(
          'setMessages',
          _.map(data, (msg) => {
            return {
              ...msg,
              id: `${msg._id}`,
              createDt: new Date(msg.createDt),
            };
          })
        );
      } catch (err) {
        console.error(err);
      }
    },
    banUser({ commit }, userId) {
      commit('banUser', userId);
    },
  },
};
