var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{attrs:{"elevation":"4","height":620,"max-width":500}},[_c('v-toolbar',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","flat":""}},[_c('v-tabs',{attrs:{"value":_vm.selectedRoomIndex},on:{"change":function($event){return _vm.setSelectedRoomIndex($event)}}},[_c('v-tabs-slider',{attrs:{"color":"secondary"}}),_vm._l((_vm.rooms),function(room){return _c('v-tab',{key:room.id},[_vm._v(" "+_vm._s(room.title)+" ")])})],2),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-close ")])],1)],1),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('div',{staticStyle:{"width":"100%"}},[_c('div',{ref:"messages",staticClass:"mb-2 pl-2 pr-2",staticStyle:{"height":"454px","overflow":"auto","width":"100%"}},_vm._l((_vm.roomMessages),function(item){return _c('div',{key:("chat-message-" + (item.id)),style:({
            cursor:
              _vm.userId === item.author.id || item.room === 'tech-support'
                ? 'default'
                : 'pointer',
          }),attrs:{"title":_vm.userId === item.author.id || item.room === 'tech-support'
              ? ''
              : 'Двойное нажатия для игнора'},on:{"dblclick":function($event){return _vm.onMessageDblClick(item)}}},[_c('strong',[_vm._v(_vm._s(_vm.userId === item.author.id ? "Я" : item.author.name))]),_vm._v(": "+_vm._s(item.data.text)+" ")])}),0),_c('div',{staticClass:"pl-2 pr-2 d-flex",staticStyle:{"width":"100%"}},[_c('v-text-field',{attrs:{"label":"Сообщение","placeholder":"Введите ваше сообщение","outlined":"","value":_vm.message,"hint":"сообщение до 300 символов","persistent-hint":"","min-width":"300px"},on:{"keyup":_vm.onKeyPress,"input":_vm.onMessageChange}}),_c('v-btn',{staticClass:"mt-2 ml-2",attrs:{"color":"primary","dark":"","disabled":_vm.message == null || !_vm.message.trim()},on:{"click":_vm.sendMessage}},[_c('v-icon',[_vm._v("mdi-send")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }