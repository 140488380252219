<template>
  <div class="grid-month">
    <slot />
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "GridMonth",
  mixins: [themeMixin],
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.grid-month {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
@media only screen and (min-width: 768px) {
  .grid-month {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (min-width: 1024px) {
  .grid-month {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media only screen and (min-width: 1240px) {
  .grid-month {
    grid-template-columns: repeat(7, 1fr);
  }
}
</style>
