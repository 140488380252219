<template>
  <div class="content__item">
    <v-img
      v-if="image"
      :src="image"
      :aspect-ratio="imageAspectRatio"
      :contain="imageMode === 'contain'"
    />
    <div
      v-if="title || text"
      class="pa-4 mb-4"
      style="background-color: #0005; border-radius: 8px"
    >
      <h2
        v-if="title"
        style="font-weight: 400; text-shadow: 1px 1px 2px black; color: white"
        class="ma-4 d-flex justify-start"
      >
        {{ title }}
      </h2>
      <template v-if="text">
        <p
          v-for="(str, i) in text"
          v-text="str"
          :key="i"
          class="ma-4"
          style="text-shadow: 1px 1px 2px black; color: white"
        />
      </template>
    </div>
    <component
      v-if="cmp != null"
      v-bind:is="cmp"
      :params="params"
      :item-id="itemId"
    />
  </div>
</template>

<script>
import Video from "./Video";
import Countdown from "./Countdown";
import Calendar from "./Calendar";
import QuestContent from "./Quest/Quest";
import QuestTasks from "./Quest/Tasks";
import QuestTask from "./Quest/Task";
import Chat from "./Chat/Index";
import Gallery from "./Gallery/Index";
import ImageGallery from "./ImageGallery/Index.vue";
import Faq from "./FAQ/Index.vue";
import get from "lodash/get";

export default {
  name: "AppContentItem",
  props: ["params", "type", "itemId", "mapping"],
  computed: {
    cmp() {
      if (this.mapping && this.mapping[this.type]) {
        return this.mapping[this.type];
      }
      switch (this.type) {
        case "video":
          return Video;
        case "countdown":
          return Countdown;
        case "calendar":
          return Calendar;
        case "quest":
          return QuestContent;
        case "quest-tasks":
          return QuestTasks;
        case "quest-task":
          return QuestTask;
        case "gallery":
          return Gallery;
        case "chat":
          return Chat;
        case "image-gallery":
          return ImageGallery;
        case "faq":
          return Faq;
        default:
          return null;
      }
    },
    image() {
      return get(this.params, "image") || null;
    },
    imageAspectRatio() {
      return get(this.params, "image-aspect-ratio") || null;
    },
    imageMode() {
      return get(this.params, "image-mode") || null;
    },
    title() {
      return get(this.params, "title") || null;
    },
    text() {
      const text = get(this.params, "text") || null;
      if (typeof text === "string") {
        return text.split("\n");
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
.content__item {
  img {
    object-fit: contain;
    width: 100%;
  }
}
</style>