<template>
  <div class="pa-4">
    <div class="ma-4 pb-2" v-if="!isFinished">
      <p>Правила вызова:</p>
      <p>
        2️⃣ разница в выпавшем номинале уходит в копилку того, чьё число выше
        (6:1 = +5)
      </p>
      <p>4️⃣ по итогам трёх раундов мы начисляем итог в зачёт обоих игроков</p>
      <p>
        🔴🔴 Во благо честной игры за бездействие во время вызова НИКТО не
        получает баллов!
      </p>
    </div>
    <div
      v-if="isFinished"
      class="d-flex flex-row pa-4 mb-4"
      :class="{
        'yellow lighten-3': isWin,
        'deep-orange lighten-3': isLoose,
        'cyan lighten-5': isDraw,
        'gray lighten-5': isCancelled,
      }"
    >
      <div v-if="isWin">
        Поздравляем с победой! Ваш выигрыш: {{ challenge.price }}
      </div>
      <div v-if="isLoose">Увы! Ваш проигрыш: {{ challenge.price }}</div>
      <div v-if="isDraw">Ничья</div>
      <div v-if="isCancelled">Отменено</div>
    </div>
    <v-simple-table>
      <tbody>
        <tr
          :class="{
            'yellow lighten-3': challenge.winner === challenge.from.id,
          }"
        >
          <td>
            <v-avatar class="ma-2" color="primary">
              <v-img
                v-if="challenge.from.thumbnail"
                :src="challenge.from.thumbnail"
                :alt="challenge.from.name"
              />
              <span v-else class="white--text text-h6">{{
                getInitials(challenge.from.name)
              }}</span>
            </v-avatar>
          </td>
          <td>{{ challenge.from.name }}</td>
          <td v-for="(roll, index) in rolls" :key="`round-from-${index}`">
            <h2>{{ roll["from"] }}</h2>
          </td>
          <td></td>
        </tr>
        <tr
          :class="{
            'yellow lighten-3': challenge.winner === challenge.to.id,
          }"
        >
          <td>
            <v-avatar class="ma-2" color="primary">
              <v-img
                v-if="challenge.to.thumbnail"
                :src="challenge.to.thumbnail"
                :alt="challenge.to.name"
              />
              <span v-else class="white--text text-h6">{{
                getInitials(challenge.to.name)
              }}</span>
            </v-avatar>
          </td>
          <td>{{ challenge.to.name }}</td>
          <td v-for="(roll, index) in rolls" :key="`round-to-${index}`">
            <h2>{{ roll["to"] }}</h2>
          </td>
          <td></td>
        </tr>
      </tbody>
    </v-simple-table>
    <div class="d-flex flex-row pa-4 justify-center">
      <v-btn :disabled="!rollEnabled" @click="onMakeRoll" color="primary"
        >{{ !isFinished && remaining ? `${remaining} | ` : "" }}Бросить
        кубик</v-btn
      >
    </div>
  </div>
</template>

<script>
import _ from "lodash";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  props: [
    "challenge",
    "is-my",
    "user-id",
    "to-user-id",
    "from-user-id",
    "remaining",
  ],
  name: "GodOfRandom",
  data() {
    return {
      getInitials,
    };
  },
  computed: {
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.challenge.status
      );
    },
    isCancelled() {
      return this.challenge.status === "cancelled";
    },
    isWin() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.winner === this.userId
      );
    },
    isLoose() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.price > 0 &&
        this.challenge.winner !== this.userId
      );
    },
    isDraw() {
      return this.isFinished && !this.isCancelled && this.challenge.price === 0;
    },
    rolls() {
      const items = this.challenge.state.rolls;
      return _.times(3, (index) => {
        return {
          from: this.getRollNumber(items, index, "from"),
          to: this.getRollNumber(items, index, "to"),
        };
      });
    },
    property() {
      return this.isMy ? "from" : "to";
    },
    opponentProperty() {
      return this.isMy ? "to" : "from";
    },
    rollEnabled() {
      if (this.isFinished) return false;
      const items = this.challenge.state.rolls;
      if (items.length === 0) return true;
      const last = _.last(items);
      return (
        last[this.property] == null ||
        (last[this.property] != null &&
          last[this.opponentProperty] != null &&
          items.length < 3)
      );
    },
  },
  methods: {
    onMakeRoll() {
      this.$emit("command", { command: "roll", params: {} });
    },
    getRollNumber(rolls, index, property) {
      console.log({ rolls, index, property });
      if (rolls.length < index) {
        return "-";
      } else if (rolls[index] && rolls[index][property] != null) {
        return rolls[index][property];
      }
      return "-";
    },
  },
};
</script>