<template>
  <div
    style="position: relative"
    class="d-flex align-center justify-center text-center"
  >
    <div :style="{ opacity: lost ? '0.25' : '1' }">
      <b-markers></b-markers>

      <div class="b-battlefield b-canvas-wrapper">
        <div class="b-grid">
          <table class="b-grid-table">
            <tbody>
              <tr>
                <td
                  v-for="gridItem in items"
                  :key="gridItem.index"
                  class="b-grid-item"
                  v-bind:class="[
                    gridItem.classes,
                    {
                      'bb-grid-item--miss': gridItem.miss,
                      'bb-grid-item--miss-auto': gridItem.missAuto,
                      'bb-grid-item--hit': gridItem.hit,
                      'bb-grid-item--done': gridItem.done,
                    },
                  ]"
                >
                  <div
                    class="b-grid-item-content d-flex align-center justify-center"
                    :class="{
                      'ship-alive': gridItem.ship && !gridItem.played,
                      'ship-dead': gridItem.ship && gridItem.played,
                    }"
                  >
                    <span class="b-grid-item-status"></span>

                    <img
                      v-if="gridItem.ship && !gridItem.played"
                      :src="require('./assets/alive-2.svg')"
                      style="width: 50%; height: 50%"
                    />
                    <img
                      v-else-if="gridItem.ship && gridItem.played"
                      :src="require('./assets/dead-2.svg')"
                      style="width: 50%; height: 50%"
                    />
                    <img
                      v-else-if="gridItem.miss || gridItem.missAuto"
                      :src="require('./assets/miss.svg')"
                      style="width: 50%; height: 50%"
                    />
                    <img
                      v-else-if="gridItem.hit"
                      :src="require('./assets/partial-hit.svg')"
                      style="width: 50%; height: 50%"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div style="position: absolute" v-if="lost">
      <img
        class="ab-status-lost"
        :src="require('./assets/ab-status-lost.png')"
      />
    </div>
  </div>
</template>

<script>
import Markers from "./Markers";
import _ from "lodash";

export default {
  name: "Battlefield",
  props: ["store", "lost"],
  computed: {
    items() {
      const result = _.reduce(
        this.store.gridItems,
        (acc, item) => {
          return [...acc, ...item];
        },
        []
      );
      return result;
    },
  },
  created: function () {
    this.createMyShips();
  },
  methods: {
    createMyShips() {},
  },
  components: {
    "b-markers": Markers,
  },
};
</script>

<style scoped>
.ship-alive {
  outline: 2px solid #16a3d9;
  border: 1px solid #16a3d9;
  z-index: 5;
}
.ship-dead {
  outline: 2px solid #ec0606;
  border: 1px solid #ec0606;
  z-index: 5;
}
</style>