<template>
  <div>
    <div
      class="pa-2 text-h5 white--text"
      style="text-shadow: 1px 1px 2px black"
    >
      {{ title }}
    </div>
    <v-row>
      <v-col
        v-for="item in tasks"
        :key="`${item.gameTask._id}`"
        cols="12"
        sm="6"
        md="4"
        lg="4"
        xl="3"
      >
        <quest-task-card
          :gameTask="item.gameTask"
          :questTask="item.questTask"
          @click="$emit('select', $event)"
          :disabled="dependency[item.gameTask._id]"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import QuestTaskCard from "./TaskCard";
export default {
  name: "QuestTasksList",
  props: ["tasks", "title", "dependency"],
  components: {
    QuestTaskCard,
  },
};
</script>