<template>
  <v-card :elevation="4" style="cursor: pointer" @click="$emit('click')">
    <v-img
      :src="url"
      :aspect-ratio="aspectRatio"
      :contain="mode === 'contain'"
      @dblclick="onDoubleClick"
    />
    <v-card-text v-if="title || description">
      <h1 v-if="title">{{ title }}</h1>
      <div v-if="description">{{ description }}</div>
    </v-card-text>
  </v-card>
</template>

<script>
import likeableMixin from "@/mixins/likeable";

export default {
  name: "GalleryItem",
  props: ["url", "title", "description", "aspect-ratio", "mode"],
  mixins: [likeableMixin],
  computed: {
    likeableObjectId() {
      return this.id;
    },
  },
  methods: {
    onDoubleClick() {
      if (this.likeable) {
        this.isLikedByUser = !this.isLikedByUser;
        console.log(this.userLikes);
      }
    },
    onZoomClick() {
      window.open(this.url, "_blank");
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
</style>
