<template>
  <div>
    <template v-if="isAllDataFetched">
      <v-row v-if="leaders.length > 0">
        <v-col
          :cols="12"
          v-for="item in leaders"
          xl="4"
          lg="4"
          md="4"
          sm="6"
          xs="12"
          :key="`gallery-leader-item-${item.id}`"
        >
          <gallery-item
            :url="item.url"
            :author="galleryShowAuthor ? item.userName : null"
            :id="item.id"
            :likeable="isLikeable"
            :total="isShowTotal ? likes && likes[item.id] : null"
            :leader="item.leader"
            :bonus="item.bonus"
            :aspect-ratio="aspectRatio"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          :cols="12"
          v-for="item in restItems"
          xl="4"
          lg="4"
          md="4"
          sm="6"
          xs="12"
          :key="`gallery-item-${item.id}`"
        >
          <gallery-item
            :url="item.url"
            :author="galleryShowAuthor ? item.userName : null"
            :id="item.id"
            :likeable="isLikeable"
            :total="isShowTotal ? likes && likes[item.id] : null"
            :aspect-ratio="aspectRatio"
          />
        </v-col>
      </v-row>
    </template>
    <div
      class="d-flex align-center justify-center"
      v-else
      style="height: 200px; width: 100%"
    >
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import _ from "lodash";
import GalleryItem from "./GalleryItem";

export default {
  name: "Gallery",
  props: ["params", "item-id"],
  components: {
    GalleryItem,
  },
  data() {
    return {
      isEventLikesFetched: false,
    };
  },
  created() {
    this.fetchLikes();
  },
  computed: {
    ...mapGetters({
      event: "event/event",
      likes: "event/likes",
      user: "event/user",
    }),
    galleryName() {
      return this.params.name;
    },
    aspectRatio() {
      return this.params.aspectRatio;
    },
    galleryShowAuthor() {
      return !!this.params.showAuthor;
    },
    items() {
      const items = _.get(this.event, `meta.${this.galleryName}`) || [];
      if (this.isSortedByLikes) {
        const itemsWithLikes = items.map((item) => {
          return {
            ...item,
            likesCount: this.likes[item.id] || 0,
          };
        });
        return _.orderBy(itemsWithLikes, ["likesCount"], ["desc"]);
      }
      return this.isRandom ? _.shuffle(items) : items;
    },
    leaders() {
      if (this.winnersPointsArray.length > 0 && this.isSortedByLikes) {
        return _.slice(this.items, 0, this.winnersPointsArray.length).map(
          (item, index) => {
            return {
              ...item,
              leader: true,
              bonus: this.winnersPointsArray[index],
            };
          }
        );
      }
      return [];
    },
    restItems() {
      if (this.winnersPointsArray.length > 0 && this.isSortedByLikes) {
        return _.slice(this.items, this.winnersPointsArray.length).map(
          (item) => {
            return {
              ...item,
              leader: false,
              bonus: null,
            };
          }
        );
      } else {
        return this.items.map((item) => {
          return {
            ...item,
            leader: false,
            bonus: null,
          };
        });
      }
    },
    isLikeable() {
      return this.params.likeable === true;
    },
    isShowTotal() {
      return this.params.showTotal === true;
    },
    isRandom() {
      return this.params.randomize === true;
    },
    winnersRaw() {
      return this.params.winners;
    },
    winnersPointsArray() {
      if (_.isEmpty(this.winnersRaw)) return [];
      const winnersNumbers = this.winnersRaw
        .split(",")
        .map((item) => item.trim())
        .map((item) => Number.parseInt(item))
        .filter((item) => !Number.isNaN(item));
      return winnersNumbers;
    },
    isSortedByLikes() {
      return this.params.sortedByLikes === true;
    },
    isAllDataFetched() {
      return this.user && this.isEventLikesFetched;
    },
  },
  methods: {
    ...mapActions({
      getLikes: "event/getLikes",
    }),
    async fetchLikes() {
      await this.getLikes();
      this.isEventLikesFetched = true;
    },
  },
};
</script>

<style scoped>
.gallery-title {
  word-break: normal;
}
</style>
