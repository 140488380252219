<template>
  <div
    class="calendar-item"
    :class="classList"
    :style="styleList"
    @click="clickable && $emit('click')"
  >
    <div class="calendar-item__number" v-if="showNumber">
      {{ day }}
    </div>
  </div>
</template>

<script>
import themeMixin from "@/mixins/eventTheme";
export default {
  name: "DayOfMonth",
  mixins: [themeMixin],
  props: {
    day: {
      type: [Number, String],
      default: null,
    },
    fileNameTemplate: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    forceBackground: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    backgroundImage: {
      type: String,
      default: null,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    showNumber: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classList() {
      return [
        this.active && "is-active",
        this.disabled && "is-disabled",
        !this.clickable && "is-not-clickable",
      ];
    },
    styleList() {
      if (this.backgroundImage) {
        return {
          backgroundImage: `url(${this.backgroundImage})`,
          border: this.showBorder ? "2px solid #fff" : null,
        };
      }

      const fileName = (this.fileNameTemplate || `december_#day#.png`).replace(
        "#day#",
        this.day
      );
      return {
        border: this.showBorder ? "2px solid #fff" : null,
        backgroundImage:
          this.day &&
          (!this.disabled || this.forceBackground) &&
          "url(" + require("../assets/images/days/" + fileName) + ")",
      };
    },
  },
};
</script>

<style lang="scss">
@import "../assets/styles/_variables.scss";
.calendar-item {
  $this: &;
  position: relative;
  width: 100%;
  // border: 2px solid #fff;
  color: #fff;
  border-radius: 1px;
  background: center/calc(100% - 6px) no-repeat;
  text-shadow: 1px 1px 3px #292929;
  // background-color: #ff0000aa;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.is-active {
    font-weight: 600;
    color: $color-main;
    border: 3px solid $color-main;
  }
  &.is-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  &.is-not-clickable {
    cursor: not-allowed;
  }

  &::before {
    content: "";
    padding-bottom: 100%;
    display: flex;
  }

  &__number {
    position: absolute;
    left: 6px;
    top: 0px;
    font-size: 18px;
  }
  &:hover {
    transform: scale(1.1);
  }
}
</style>
