<template>
  <game
    :user-id="userId"
    :challenge="challenge"
    @command="onCommand"
    :remaining="remaining"
  />
</template>

<script>
import _ from "lodash";
import Game from "./Game/Index.vue";

const getInitials = (name) => {
  if (typeof name !== "string") {
    return null;
  }
  if (name === "") return "";
  return name
    .split(" ")
    .filter((item) => item !== "")
    .map((item) => `${item[0]}`.toUpperCase())
    .reduce((acc, item) => {
      if (acc.length >= 2) return acc;
      return `${acc}${item}`;
    }, "");
};

export default {
  props: [
    "challenge",
    "is-my",
    "user-id",
    "to-user-id",
    "from-user-id",
    "remaining",
  ],
  components: {
    Game,
  },
  name: "AirBattle",
  data() {
    return {
      getInitials,
    };
  },
  computed: {
    isFinished() {
      return ["cancelled", "expired", "resolved"].includes(
        this.challenge.status
      );
    },
    isCancelled() {
      return this.challenge.status === "cancelled";
    },
    isWin() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.winner === this.userId
      );
    },
    isLoose() {
      return (
        this.isFinished &&
        !this.isCancelled &&
        this.challenge.price > 0 &&
        this.challenge.winner !== this.userId
      );
    },
    isDraw() {
      return this.isFinished && !this.isCancelled && this.challenge.price === 0;
    },
    rolls() {
      const items = this.challenge.state.rolls;
      return _.times(3, (index) => {
        return {
          from: this.getRollNumber(items, index, "from"),
          to: this.getRollNumber(items, index, "to"),
        };
      });
    },
    property() {
      return this.isMy ? "from" : "to";
    },
    opponentProperty() {
      return this.isMy ? "to" : "from";
    },
    rollEnabled() {
      if (this.isFinished) return false;
      const items = this.challenge.state.rolls;
      if (items.length === 0) return true;
      const last = _.last(items);
      return (
        last[this.property] == null ||
        (last[this.property] != null &&
          last[this.opponentProperty] != null &&
          items.length < 3)
      );
    },
  },
  methods: {
    onCommand(args) {
      this.$emit("command", args);
    },
    onMakeRoll() {
      this.$emit("command", { command: "roll", params: {} });
    },
    getRollNumber(rolls, index, property) {
      console.log({ rolls, index, property });
      if (rolls.length < index) {
        return "-";
      } else if (rolls[index] && rolls[index][property] != null) {
        return rolls[index][property];
      }
      return "-";
    },
  },
};
</script>